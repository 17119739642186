@import 'vars';
@import 'colors';

.custom-attr__wrapper {
  overflow: auto;

  h3 {
    text-transform: capitalize;
  }

  &.darkmode {
    background-color: $darkmode-background;
    color: $darkmode-text;

    h3 {
      color: $darkmode-text;
    }

    p.sub-header {
      color: $darkmode-darker-text;
    }

    .list-group-item {
      background-color: $darkmode-darker-background;
    }

    .custom-attr-item__input {
      color: $darkmode-text;
      background-color: $darkmode-background;
    }
  }
}

.custom-attr__list-wrapper {
  li.list-group-item {
    width: 80%;
    margin-right: 5px;
    display: flex;
    justify-content: space-between;

    .character-list__attribute-name {
      align-self: center;
      margin: 0;
    }

    .custom-attr-item__checkbox-label {
      margin-right: 50px;
    }

    @media (max-width: 1024px) {
      width: 100%;
    }

    @media (max-width: 500px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: inherit;
    }
  }
}

.custom-attr__add-button {
  width: 50%;
  display: flex;

  .btn {
    margin-left: 7px;
    margin-top: 11px;
    align-self: center;
  }
}

.custom-attr__save-as-template {
  margin-right: 24px;
}

@media (max-width: 824px) {
  .custom-attr__add-button {
    width: 100% !important;
  }

  .custom-attr__save-as-template {
    margin-right: 0;
  }
}

.custom-attr-item {
  display: flex;
  align-items: center;
}

.custom-attr-item__input {
  align-self: center;
  margin: 0;
  border: 0;
  padding: 1px 4px;
}

@media (max-width: 824px) {
  .custom-attr-item__input {
    width: 70%;
  }
}

.custom-attr-item__input--hidden {
  display: none;
}

.custom-attr-item__checkbox-label {
  margin: 0 !important;
  align-items: center;
  display: flex;
  width: 140px;

  input {
    margin-right: 8px !important;
    align-self: center;
  }
}

@media (max-width: 1024px) {
  .custom-attr-item__checkbox-label {
    width: 100%;
    margin-right: 5px !important;
  }
}

.custom-attr-item__checkbox-wrapper {
  display: flex;
  justify-content: space-between;
}

.custom-attr-item__left-side {
  display: flex;
  align-items: center;
  flex: 1;
}

.custom-attr-item__drag-handle {
  cursor: move;
  width: 30px;
  color: $gray-5;
  font-size: 12px;
}

.darkmode {
  .custom-attr__wrapper.darkmode {
    h3 {
      color: #ccc;
    }

    p.sub-header {
      color: #bbb;
    }

    .list-group-item {
      background-color: $darkmode-v2-background;
      border: $darkmode-v2-border solid 1px;
    }

    .custom-attr-item__input {
      background-color: $darkmode-v2-background;
    }

    .custom-attr-item__input:focus-visible {
      outline: $darkmode-v2-border solid 1px;
      border: $darkmode-v2-border solid 1px;
    }
  }
}
