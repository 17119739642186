@import '../colors';
@import '../vars';

.font-setting__wrapper {
  display: flex;
  justify-content: space-between;

  .font-setting__list {
    overflow-y: auto;
    max-width: 34%;
    display: flex;
    h1,
    h2 {
      @include application-text;
      font-size: 20pt;
      margin: 0;
      margin-bottom: 10px;
      &.margin-bottom {
        margin-bottom: 20px;
      }
    }
    h2 {
      @include sub-header-font;
      color: $gray-4;
    }

    h1 {
      @include header-font;
    }

    li.list-group-item:hover {
      background-color: $gray-9;
    }

    li.list-group-item.selected {
      background-color: $gray-8;
    }

    @media (max-width: 824px) {
      h1 {
        font-size: 14pt !important;
        word-break: break-all;
      }
    }

    ul.list-group {
      max-width: 300px;
    }

    li.list-group-item {
      cursor: pointer;
      text-overflow: ellipsis;

      button {
        margin-left: 8px;
      }

      @media (max-width: 824px) {
        word-break: break-all;
      }
    }

    select.form-control {
      width: auto;
      margin-bottom: 16px;
    }
  }

  .font-setting__details {
    flex: 1;
    margin-left: 20px;
    display: flex;
    width: 100%;

    .panel-heading {
      @include application-text;
      .font-setting-picker__link {
        margin-left: 8px;
        vertical-align: middle;
        cursor: pointer;
      }
      h3.panel-title {
        @include application-text;
      }
      p {
        margin-top: 12px;
        margin-bottom: 0;
      }
    }

    .panel-body {
      width: 100%;

      hr {
        width: 100%;
      }

      .dashboard__options__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        > span:first-child {
          width: 34%;
          margin: 0;
        }

        hr {
          width: 100%;
        }

        .dashboard__options__item__font-setting__heading {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: center;

          h4 {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 0;
          }
        }

        svg {
          cursor: pointer;
        }

        h4 {
          display: flex;
          margin: 0;
          margin-right: 20px;
        }
      }
    }

    .dashboard__options__item__column {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-self: flex-start;

      > * {
        margin-top: 5px;
      }
    }

    .form-horizontal {
      padding: 16px;
      .btn-toolbar {
        display: flex;
        justify-content: flex-end;
      }
    }
    .table {
      margin-bottom: 0;
    }
  }
}

.darkmode {
  .font-setting__wrapper {
    .font-setting__list {
      li.list-group-item:hover {
        background-color: #24252c;
      }

      li.list-group-item.selected {
        background-color: #24252c;
      }
    }
  }
}
