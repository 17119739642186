@import 'vars';

.slate-editor__wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  p,
  ul,
  ol {
    &:not(.dropdown-menu) {
      @include rce-text-font;
    }
  }
  &.readonly {
    margin: 0;
  }

  .slate-editor__editor {
    p,
    ul,
    ol {
      @include rce-text-font;
    }
    &.global__setting {
      padding: 10px;
      position: relative;
      &.light {
        background-color: $gray-9;
        color: #102a42;

        p {
          @include rce-text-font;
        }

        h1 {
          @include header-font;
          @include rce-title-font;
        }

        h2 {
          @include sub-header-font;
          @include rce-subtitle-font;
        }
      }

      &.darkmode {
        background-color: $darkmode-v2-card-background;
        color: #ccc;

        p {
          @include rce-text-font;
          @include rce-darkmode-font-color;
        }

        h1 {
          @include header-font;
          @include rce-title-font;
          @include rce-title-darkmode-font-color;
        }

        h2 {
          @include sub-header-font;
          @include rce-subtitle-font;
          @include rce-subtitle-darkmode-font-color;
        }
      }
    }
  }
}

.slate-editor__toolbar-wrapper {
  margin-bottom: 5px;
  width: 100%;
  .btn-toolbar {
    flex-direction: row;
    .btn {
      border-radius: 2px;
      font-size: 14px;
      svg {
        vertical-align: text-bottom;
      }
    }

    .dropdown {
      button {
        width: 160px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      ul {
        max-height: 200px;
        overflow: auto;
      }

      &.size-picker {
        button {
          width: 60px;
        }
        .dropdown-menu {
          min-width: 60px;
          width: 60px;
        }
      }
    }
  }
  p,
  ul,
  ol {
    &:not(.dropdown-menu) {
      @include rce-text-font;
    }
  }
  &.readonly {
    display: none;
  }
  &.darkmode {
    .btn {
      background-color: #888;
      color: $darkmode-text;
      border-color: $darkmode-darker-border;
    }
    .btn-primary {
      background-color: $gray-8;
      color: $darkmode-background;
    }

    p,
    ul,
    ol {
      &:not(.dropdown-menu) {
        @include rce-text-font;
        @include rce-darkmode-font-color;
      }
    }

    h1 {
      @include header-font;
      @include rce-title-font;
      @include rce-title-darkmode-font-color;
    }

    h2 {
      @include sub-header-font;
      @include rce-subtitle-font;
      @include rce-subtitle-darkmode-font-color;
    }
  }

  .rceLocked {
    pointer-events: none !important;
  }
}

.lock-icon__wrapper {
  position: absolute;
  cursor: pointer;
  left: 45%;
  top: 25%;
  text-align: center;
  z-index: 100;
  width: max-content;
  padding: 30px;
  opacity: 80%;
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;

  svg {
    font-size: 4rem;
  }

  span {
    width: max-content;
  }

  span,
  svg {
    display: flex;
    justify-content: center;
    align-self: center;
  }
}

.editing .slate-editor__editor {
  max-height: 70vh;
  overflow-y: auto;
}

.slate-editor__editor {
  border: 1px solid $input-border;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  border-radius: $input-border-radius;
  padding: 10px 12px;
  font-size: 20px;
  cursor: text;
  color: inherit;

  p,
  ul,
  ol {
    &:not(.dropdown-menu) {
      @include rce-text-font;
    }
  }

  h1 {
    @include header-font;
    @include rce-title-font;
    @include rce-title-darkmode-font-color;
  }

  h2 {
    @include sub-header-font;
    @include rce-subtitle-font;
    @include rce-subtitle-darkmode-font-color;
  }

  &.readonly {
    border: none;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: inherit;
  }

  img.slate-editor__image-link {
    display: block;
    max-width: 100%;
    max-height: 20em;
    &.selected {
      box-shadow: 0 0 4px 3px $blue-8;
      border-radius: 4px;
    }
  }

  h1 {
    @include header-font;
    @include rce-title-font;
  }

  h2 {
    @include sub-header-font;
    @include rce-subtitle-font;
  }

  h1,
  h2 {
    margin-top: 4px;
  }

  .slate-editor__word-counter {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px;
    padding: 0px 5px;
    border-radius: $border-radius;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    border: 1px solid $new-item-border-color;
    @include body-text-font;

    > :first-child {
      margin-right: 5px;
    }
  }

  &.light {
    .slate-editor__word-counter {
      background-color: #fff;
      border: 1px solid $new-item-border-color;
      color: #102a42;
    }
  }
  &.darkmode {
    .slate-editor__word-counter {
      border: 1px solid $darkmode-v2-border;
      background-color: $darkmode-v2-background;
      color: $darkmode-v2-darker-text;
    }
  }

  [data-slate-editor='true'] p:only-child:has([data-slate-placeholder='true']) {
    display: flex;
    min-height: 22px;
    align-items: flex-end;
  }
}

.darkmode {
  .slate-editor__editor {
    color: $darkmode-v2-darker-text;
    p,
    ul,
    ol {
      &:not(.dropdown-menu) {
        @include rce-text-font;
        @include rce-darkmode-font-color;
      }
    }

    .slate-editor__word-counter {
      color: $darkmode-v2-darker-text;
      border: 1px solid $darkmode-v2-border;
      background-color: $darkmode-v2-background;
    }

    &.light {
      .slate-editor__word-counter {
        background-color: #fff;
        border: 1px solid $new-item-border-color;
        color: #102a42;
      }
    }
    &.darkmode {
      .slate-editor__word-counter {
        border: 1px solid $darkmode-v2-border;
        background-color: $darkmode-v2-background;
        color: $darkmode-v2-darker-text;
      }
    }
  }

  .slate-editor__toolbar-wrapper {
    .btn-toolbar {
      .btn-group {
        > * {
          background-color: $darkmode-v2-nav-background;
          color: $darkmode-v2-darker-text;
        }

        .btn {
          &.active {
            background-color: $darkmode-v2-darker-background;
          }

          &:focus,
          &:hover,
          &:active {
            color: $darkmode-v2-link-text;
            background-color: $darkmode-v2-background;
          }
        }
      }
    }

    .btn {
      border-color: #555;
    }
  }

  .slate-editor__wrapper {
    .slate-editor__editor {
      &:not(.light) {
        p,
        ul,
        ol {
          &:not(.dropdown-menu) {
            @include rce-text-font;
            @include rce-darkmode-font-color;
          }
        }
      }

      &.global-setting {
        &.darkmode {
          background-color: $darkmode-v2-card-background;

          p {
            @include rce-text-font;
            @include rce-darkmode-font-color;
          }

          h1 {
            @include header-font;
            @include rce-title-font;
            @include rce-title-darkmode-font-color;
          }

          h2 {
            @include sub-header-font;
            @include rce-subtitle-font;
            @include rce-subtitle-darkmode-font-color;
          }
        }

        &.light {
          background-color: $gray-9;
          p,
          ul,
          ol {
            &:not(.dropdown-menu) {
              @include rce-text-font;
              @include rce-darkmode-font-color;
            }
          }

          h1 {
            @include header-font;
            @include rce-title-font;
          }

          h2 {
            @include sub-header-font;
            @include rce-subtitle-font;
          }
        }
      }
    }
  }
}
