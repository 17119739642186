@import 'vars';

$title_height: 70px;
$cell_width: 175px;
$medium_width: 82px; // +2px to fit two-digit `Chapter` names e.g. Chapter 12

.beat__cell {
  width: $cell_width;
  padding-bottom: 45px;

  &:not(.medium-timeline):not(.small-timeline) {
    margin-top: 25px;
    padding-bottom: 40px;
  }

  &.medium-timeline {
    margin-top: 20px;
    width: 90px;
    padding-bottom: 0;
  }

  &.small-timeline {
    width: 90px;
    padding-bottom: 0;
  }
}

.beat__heading-drag-handle {
  float: left;
  opacity: 0;
  cursor: grab;
  font-size: 16px;
  margin-right: -14px;

  &.hovering {
    opacity: 1;
  }
}

.beat__heading-spacer {
  opacity: 0;
}

.beat__heading-wrapper {
}

.beat__heading {
  @include sub-header-font;
  padding: 10px 10px;
  width: 150px + 6px; // +6px for the 3px border
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  transition: all 0.4s $cubic;
  border: 3px dashed transparent;
  background-color: $body-background-color;
  outline: 5px solid $body-background-color;

  &.darkmode {
    background-color: $darkmode-background;
    outline: 5px solid $darkmode-background;
  }

  .form-group {
    text-align: left;

    .control-label {
      @include secondary-text;
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 0;
    }

    .control-label.darkmode {
      color: $darkmode-darker-text;
    }
  }

  &.hover {
    border: 3px dashed $blue-7;
    cursor: move;
  }

  &.dropping {
    border: $new-item-border;
  }

  &.medium-timeline {
    width: $medium_width + 6px; // +6px for the 3px border
    font-size: 14px;
    padding: 12px 4px;

    .form-group {
      .control-label {
        font-size: 12px;
      }

      input {
        font-size: 12px;
        padding: 6px 2px;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.beat__body {
  @include timeline-header-font;
  padding: 10px;
  text-align: center;
  transition: all 0.4s $cubic;
  border: 3px dashed transparent;

  .form-group {
    text-align: left;

    .control-label {
      @include secondary-text;
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 0;
    }

    .control-label.darkmode {
      color: $darkmode-darker-text;
    }
  }

  &.hover {
    border: 3px dashed $blue-7;
    cursor: move;
  }

  &.dropping {
    border: $new-item-border;
  }

  &.medium-timeline {
    width: $medium_width + 6px; // +6px for the 3px border
    @include medium-timeline-header-font-size;
    padding: 12px 4px;
    margin: auto;

    .form-group {
      .control-label {
        font-size: 12px;
      }

      input {
        font-size: 12px;
        padding: 6px 2px;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  span:first-child {
    word-break: break-word;
  }
}

.beat-list__item__hover-options {
  text-align: center;
  visibility: hidden;
  margin-bottom: 5px;

  &.small-timeline {
    visibility: visible;
    position: absolute !important;
    top: 41px !important;
    left: -14px !important;
    transform: none !important;
    border: none !important;
    background-color: transparent !important;
    .btn-group {
      display: flex;
      flex-direction: row;
      min-width: fit-content;
    }
  }
}

.transparent {
  visibility: hidden;
}

// .medium-lower-hover-options {
//   display: flex;
//   justify-content: center;
//   margin: 5px 0 10px;
//   button {
//     width: 27px;
//   }
// }

.insert-beat-cell:not(.vertical):not(.small-timeline) {
  &:not(.medium-timeline) {
    margin-top: 25px;
    padding-bottom: 40px;
  }

  &:not(.medium-timeline):not(.insert-child) {
    margin-top: 38px;
    padding-bottom: 40px;
    margin-left: 1px;
  }

  &.medium-timeline:not(.insert-child) {
    margin-top: 16px;
    padding-bottom: 0px;
  }
}

.beat-list__insert {
  height: 75px;
  display: flex;
  align-items: center;
  transition: all 0.4s $cubic;
  font-size: 16px;
  cursor: pointer;

  &:not(.small-timeline):not(.medium-timeline) {
    margin-left: 0px;
    margin-right: 0px;
  }

  &:last-child:not(.small-timeline):not(.medium-timeline) {
    margin-top: 8px;
  }

  &:first-child:not(.small-timeline):not(.medium-timeline) {
    height: 82.5px;
    margin: 5px 2px;
  }

  &.insert-beat {
    height: 50px;
    margin: 5px 2px;
  }

  .insert-beat-wrapper {
    .glyphicon {
      margin-top: -1px;
      margin-right: -1px;
    }
  }

  .insert-beat-wrapper.append-beat {
    &.large-timeline {
      padding: 11px 70px 10px 70px;
      margin-left: 35px;
      margin-top: 5px;
    }

    &.medium-timeline {
      margin-top: 10px;
      margin-right: 8px;
      padding: 14px 35px;
    }

    color: $blue-5;
    border: none;
    margin-right: 50px;
    border: 3px dotted $blue-8;

    &:hover {
      border: 3px dotted $blue-8;
      background-color: $blue-8;
    }
  }

  .insert-missing-beat-wrapper {
    .glyphicon {
      margin-top: -1px;
      margin-right: -1px;
    }
  }

  .insert-missing-beat-wrapper.insert-beat {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $blue-5;
    border: 3px dashed $blue-5;
  }
}

.beat-list__insert-child {
  display: flex;
  align-items: center;
  margin-top: -27px;
  transition: all 0.4s $cubic;
  font-size: 16px;
  cursor: pointer;
}

// .beat-list__interstitial-controls {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
//   align-items: center;
// }

.insert-beat-wrapper:hover {
  color: $blue-7;
}

.beat-list__insert:hover {
  color: $blue-5;

  .insert-beat-wrapper.append-beat {
  }
}

.insert-missing-beat-wrapper:hover {
  color: $blue-7;
}

.beat-list__insert:hover {
  color: $blue-5;

  .insert-missing-beat-wrapper.insert-beat {
    border: none;
  }
}

.sticky-table.darkmode {
  .beat-list__insert:hover {
    color: $blue-8;
  }

  .beat-list__insert {
    .insert-beat-wrapper.append-beat {
      color: $blue-7;
    }
  }
}

// ///////////
// Vertical
// ///////////

.vertical-beat__cell {
  max-width: $cell_width + 25px;

  &.medium-timeline {
    width: 96px;
  }
}

.vertical-beat__body {
  @include timeline-header-font;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  min-height: $title_height;
  min-width: 150px;
  padding: 10px;
  transition: all 0.4s $cubic;
  border: 3px dashed transparent;

  .form-group {
    text-align: left;

    .control-label {
      @include secondary-text;
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 0;
    }

    .control-label.darkmode {
      color: $darkmode-darker-text;
    }
  }

  &.medium-timeline {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: $medium_width + 6px; // +6px for the 3px border
    width: $medium_width + 16px; // +6px for the 3px border
    font-size: 14px;
    padding: 12px 8px;
    @include medium-timeline-header-font-size;

    .form-group {
      .control-label {
        font-size: 12px;
      }

      input {
        font-size: 12px;
        padding: 6px 2px;
      }
    }
  }

  span:first-child {
    word-break: break-word;
  }
}

.vertical-beat__body.hover {
  border: 3px dashed $blue-7;
  cursor: move;
}

.vertical-beat__body.dropping {
  border: $new-item-border;
}

.vertical-beat-list__item__hover-options {
  float: left;
  margin-right: 3px;
  margin-left: 2px;
  margin-top: -20px;
  visibility: hidden;

  button.btn-block {
    margin-top: 1px;
  }

  &.small-timeline {
    visibility: visible;
    float: none !important;
    position: absolute;
    top: -1px;
    left: 100px;
  }
}

.vertical-beat-list__insert {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  transition: all 0.4s $cubic;
  font-size: 16px;
  cursor: pointer;
  margin: -5px 11px 0 52px;

  button {
    // width: 41px;
    // height: 35px;
  }

  .vertical-insert-beat-wrapper {
    .large-timeline {
      height: 40px;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .vertical-insert-beat-wrapper.insert-beat-spacer {
    .glyphicon {
      position: relative;
    }
  }

  &.medium-timeline {
    width: $medium_width;
    margin-left: 10px;
  }
}

.vertical-medium-lower-hover-options {
  float: right;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: -70px -37px 10px 0px;

  button {
    height: 35px;
    width: 41px;
  }

  &:last-child:not(.small-timeline) {
    margin-left: 2px;
  }
}

.vertical-insert-beat-wrapper:hover {
  color: $blue-7;
}

.vertical-beat-list__insert.append-beat {
  @extend .vertical-beat-list__insert;
  height: $title_height;
  margin-bottom: 50px;
  margin-right: 0px;
  .vertical-insert-beat-wrapper.append-beat {
    padding: 20px $title_height;
    color: $blue-5;
    border: 3px dotted $blue-8;
    margin-top: 10px;
  }

  .vertical-insert-beat-wrapper.append-beat:hover {
    border: 3px dotted $blue-8;
    background-color: $blue-8;
  }

  &.medium-timeline {
    width: $medium_width;
    .vertical-insert-beat-wrapper.append-beat {
      padding: 12px 32px;
    }
  }
}

.vertical-beat-list__insert.append-beat.medium-timeline {
  height: 43px;
}

.vertical-beat-list__insert:hover {
  color: $blue-5;

  .insert-beat-wrapper.append-beat {
    border: 3px dashed $blue-7;
  }
}

.beat-list__insert--always-visible {
  color: $blue-5;

  .insert-beat-wrapper.append-beat {
    border: 3px dashed $blue-7;
  }
}

.darkmode {
  .beat__heading.darkmode {
    background-color: $darkmode-v2-card-background;
    outline: 5px solid $darkmode-v2-card-background;
  }

  .control-label {
    color: $darkmode-v2-heading-text;
  }
}
