@import '../colors';
@import '../vars';

.dashboard__templates {
  height: 100%;
  overflow-y: auto;
  padding: 20px 40px;

  .dashboard__search {
    position: absolute;
    top: 55px;
    right: 15px;
    width: 300px;
  }
}

.dashboard__template-section {
  margin-bottom: 20px;

  h1 {
    font-size: 20pt;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.dashboard__template-section__wrapper {
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dashboard__template-section__item {
  // min-width: 125px;
  // max-width: 200px;
  // min-height: 93px;
  // max-height: 150px;
  width: 200px;
  height: 123px;
  overflow: hidden;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.1s ease-in;
  // border: 2px solid $blue-5;
  border: 2px solid $gray-7;
  padding: 12px;
  margin: 16px;
  font-size: larger;

  &.custom {
    // border-color: $green-5;
  }

  &.starter {
    // border-color: $red-5;
  }

  &:hover {
    color: $blue-0;
    border-color: $blue-5;
    background-color: $blue-9;
    box-shadow: $blue-highlight-box-shadow;
  }
}
