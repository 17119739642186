$gray-0: hsl(209, 61%, 16%);
$gray-1: hsl(211, 39%, 23%);
$gray-2: hsl(209, 34%, 20%);
$gray-3: hsl(209, 28%, 39%);
$gray-4: hsl(210, 22%, 49%);
$gray-5: hsl(209, 23%, 60%);
$gray-6: hsl(211, 27%, 70%);
$gray-7: hsl(210, 31%, 80%);
$gray-8: hsl(212, 33%, 89%);
$gray-9: hsl(210, 36%, 96%);

$neutral-gray-0: hsl(0, 0%, 13%);
$neutral-gray-1: hsl(0, 0%, 23%);
$neutral-gray-2: hsl(0, 0%, 32%);
$neutral-gray-3: hsl(0, 0%, 38%);
$neutral-gray-4: hsl(0, 0%, 49%);
$neutral-gray-5: hsl(0, 0%, 62%);
$neutral-gray-6: hsl(0, 0%, 69%);
$neutral-gray-7: hsl(0, 0%, 81%);
$neutral-gray-8: hsl(0, 0%, 88%);
$neutral-gray-8-half: hsl(0, 0%, 92%);
$neutral-gray-9: hsl(0, 0%, 97%);

$blue-0: hsl(218, 100%, 17%);
$blue-1: hsl(216, 98%, 25%);
$blue-2: hsl(215, 96%, 32%);
$blue-3: hsl(214, 95%, 36%);
$blue-4: hsl(212, 92%, 43%);
$blue-5: hsl(210, 83%, 53%);
$blue-6: hsl(208, 88%, 62%);
$blue-7: hsl(206, 93%, 73%);
$blue-8: hsl(204, 100%, 86%);
$blue-9: hsl(202, 100%, 95%);
$blue-9-alpha: hsla(202, 100%, 95%, 0.6);

$blue-highlight-box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.1), inset 0 -1px 5px 0 $blue-4,
  inset 0px 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0 1px 5px 0 $blue-4;

$orange: #ff7f32;

$orange-0: hsl(6, 96%, 26%);
$orange-1: hsl(8, 92%, 35%);
$orange-2: hsl(10, 93%, 40%);
$orange-3: hsl(12, 86%, 47%);
$orange-4: hsl(14, 89%, 55%);
$orange-5: hsl(16, 94%, 61%);
$orange-6: hsl(18, 100%, 70%);
$orange-7: hsl(20, 100%, 77%);
$orange-8: hsl(22, 100%, 85%);
$orange-9: hsl(24, 100%, 93%);

$green-0: hsl(125, 97%, 14%);
$green-1: hsl(125, 86%, 20%);
$green-2: hsl(125, 79%, 26%);
$green-3: hsl(122, 80%, 29%);
$green-4: hsl(122, 73%, 35%);
$green-5: hsl(123, 57%, 45%);
$green-6: hsl(123, 53%, 55%);
$green-7: hsl(124, 63%, 74%);
$green-8: hsl(127, 65%, 85%);
$green-9: hsl(125, 65%, 93%);

$red-0: hsl(348, 94%, 20%);
$red-1: hsl(350, 94%, 28%);
$red-2: hsl(352, 90%, 35%);
$red-3: hsl(354, 85%, 44%);
$red-4: hsl(356, 75%, 53%);
$red-5: hsl(360, 83%, 62%);
$red-6: hsl(360, 91%, 69%);
$red-7: hsl(360, 100%, 80%);
$red-8: hsl(360, 100%, 87%);
$red-9: hsl(360, 100%, 95%);

$yellow-0: hsl(15, 86%, 30%);
$yellow-1: hsl(22, 82%, 39%);
$yellow-2: hsl(29, 80%, 44%);
$yellow-3: hsl(36, 77%, 49%);
$yellow-4: hsl(42, 87%, 55%);
$yellow-5: hsl(44, 92%, 63%);
$yellow-6: hsl(48, 94%, 68%);
$yellow-7: hsl(48, 95%, 76%);
$yellow-8: hsl(48, 100%, 88%);
$yellow-9: hsl(49, 100%, 96%);

$shadow-0: rgba(0, 0, 0, 0.1);
$shadow-1: rgba(0, 0, 0, 0.15);
$shadow-2: rgba(0, 0, 0, 0.2);
