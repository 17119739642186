$blue: $blue-5;

$cubic: cubic-bezier(0.165, 0.84, 0.44, 1);

$body-background-color: $gray-9;

$darkmode-v2-background: #2a2b32;
$darkmode-v2-darker-background: #202124;
$darkmode-v2-darker-text: #eeeeee;
$darkmode-v2-text: #ffffff;
$darkmode-v2-card-background: #343641;
$darkmode-v2-darker-card-background: #202123;
$darkmode-v2-nav-background: #343641;
$darkmode-v2-link-text: #bbbbbb;
$darkmode-v2-anchor-text: #f47e38;
$darkmode-v2-link-border: #b03503;
$darkmode-v2-border: #444444;
$darkmode-v2-nav-tabs-border: #666666;
$darkmode-v2-btn-primary: #1e7623;
$darkmode-v2-btn-primary-border: #1e7623;
$darkmode-v2-btn-primary-hover: #1e7623;
$darkmode-v2-btn-success-hover-border: #1e7623;
$darkmode-v2-btn-danger: #bd3755;
$darkmode-v2-btn-danger-hover: #bd4761;
$darkmode-v2-btn-danger-border: #bd3755;
$darkmode-v2-btn-danger-hover-border: #bd4761;
$darkmode-v2-heading-text: #bfbfbf;
$darkmode-v2-selected-background: #19171d;

$darkmode-background: #666;
$darkmode-darker-background: #555;
$darkmode-card-background: #666;
$darkmode-text: #eee;
$darkmode-darker-text: #ccc;
$darkmode-border: #999;
$darkmode-darker-border: #777;

$height: 66px;
$width: 150px;

// $scene-height: $height + 50;

$margin-width: 25px;
$margin: 0px $margin-width 0px 0px;
$margin-left: 0px 0px 0px $margin-width;
$padding: 10px 10px 10px 10px;

$border-radius: 4px;

$card-background: $body-background-color;

$svg-line-width: 3;

$new-item-background: #ddd;
$new-item-border-color: #aaa;
$new-item-border: 2px dashed $new-item-border-color;

@mixin header-font {
  font-family: var(--global-header-font, 'IBM Plex Serif'), serif !important;
}

@mixin sub-header-font {
  font-family: var(--global-header-font, 'IBM Plex Serif'), serif !important;
}

@mixin timeline-header-font {
  font-family: var(--timeline-header-font, 'IBM Plex Serif'), serif !important;
  font-size: var(--timeline-header-font-size, '24px') !important;
}

@mixin plotline-font {
  font-family: var(--plotline-font, 'IBM Plex Serif'), serif !important;
  font-size: var(--plotline-font-size, '16px') !important;
}

@mixin scenecard-title-font {
  font-family: var(--scenecard-title-font, 'Forum'), serif !important;
  font-size: var(--scenecard-title-font-size, '16px') !important;
}

@mixin medium-timeline-header-font-size {
  font-size: calc(var(--timeline-header-font-size, '24px') * 14 / 24) !important;
}

@mixin medium-plotline-font-size {
  font-size: calc(var(--plotline-font-size, '20px') * 14 / 20) !important;
}

@mixin medium-scenecard-title-font-size {
  font-size: calc(var(--scenecard-title-font-size, '16px') * 14 / 16) !important;
}

@mixin body-text-font {
  font-family: var(--global-body-font, 'Forum'), sans-serif !important;
}

@mixin application-text {
  font-family: 'Lato', sans-serif !important;
}

@mixin rce-text-font {
  font-family: var(--rce-default-font, 'Forum'), sans-serif !important;
  font-size: var(--rce-default-font-size, 20px) !important;
  color: var(--rce-default-font-color, $gray-0) !important;
}

@mixin rce-title-font {
  font-family: var(--rce-title-font, --global-header-font, 'Forum'), sans-serif !important;
  font-size: var(--rce-title-font-size, 20px) !important;
  font-weight: var(--rce-title-font-weight, 400) !important;
  color: var(--rce-title-font-color, $gray-0) !important;
}

@mixin rce-subtitle-font {
  font-family: var(--rce-subtitle-font, 'Forum'), sans-serif !important;
  font-size: var(--rce-subtitle-font-size, 20px) !important;
  font-weight: var(--rce-subtitle-font-weight, 400) !important;
  color: var(--rce-subtitle-font-color, $gray-0) !important;
}

@mixin rce-darkmode-font-color {
  color: var(--rce-default-darkmode-font-color, $darkmode-v2-text) !important;
}

@mixin rce-title-darkmode-font-color {
  color: var(--rce-title-darkmode-font-color, $darkmode-v2-text) !important;
}

@mixin rce-subtitle-darkmode-font-color {
  color: var(--rce-subtitle-darkmode-font-color, $darkmode-v2-text) !important;
}

@mixin user-text {
  font-family: 'Forum', sans-serif !important;
}

@mixin secondary-text {
  color: rgba(22, 34, 45, 0.7); // #16222d
}

@mixin disabled-text {
  color: rgba(22, 34, 45, 0.2); // #16222d
}

@mixin accented-text {
  color: rgba(108, 172, 228, 0.9); // #6cace4
}

@mixin hover-box {
  background-color: $new-item-background;
  border-radius: $border-radius;
  cursor: pointer;
}

@mixin well {
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  padding: 7px;
}

@mixin new_item {
  height: $height;
  width: $width;
  min-width: $width;
  line-height: $height;
  color: $new-item-border-color;
  text-align: center;
  margin: auto;
  margin-top: 10px;
}

@mixin new_item_hover {
  background: $new-item-background;
  border: $new-item-border;
  cursor: pointer;
}
