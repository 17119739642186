@import 'vars';

.template-picker__dialog-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.template-picker__confirmation__dialog-wrapper {
  @include application-text;
  height: 100%;
  display: grid;
  grid-template-areas: 'header' 'body' 'footer'; // for some reason you need the ''s
  grid-template-rows: auto 1fr auto;

  .template-picker__confirmation__header {
    grid-area: header;
    h3 {
      margin-top: 0;
    }
    hr {
      margin: 8px 0;
    }
  }

  .template-picker__confirmation__body {
    grid-area: body;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .text-larger {
      font-size: 18px;
    }

    .template-picker__confirmation__row {
      // $top-margin: 16px + 1px; // because of the <p>'s bottom margin above it
      margin: 13px 0 24px 0;
      display: flex;
      justify-content: space-evenly;
      .template-picker__confirmation__option {
        cursor: pointer;
        height: 120px;
        width: 175px;
        border: 1px solid $gray-6;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 12px;
        h3,
        p {
          margin: 0;
        }

        p.small {
          font-size: smaller;
        }

        p.caps {
          text-transform: uppercase;
          margin-bottom: 12px;
        }

        &.recommended {
          justify-content: space-around;
          p.caps {
            margin: 0;
          }
        }

        &.selected {
          background-color: $blue-6;
          border-color: $blue-7;
          color: white;
        }
      }
    }
  }

  .card-dialog__button-bar {
    grid-area: footer;
  }
}

.template-picker__description {
  @include application-text;
}

.template-picker__wrapper {
  display: flex;
  justify-content: space-between;
  overflow: auto;

  .template-picker__list {
    overflow: auto;
    max-width: 34%;
    h1,
    h2 {
      @include application-text;
      font-size: 20pt;
      margin: 0;
      margin-bottom: 10px;
      &.margin-bottom {
        margin-bottom: 20px;
      }
    }
    h1 {
      @include header-font;
    }
    h2 {
      @include header-font;
      font-size: 14pt;
      color: $gray-4;
    }

    @media (max-width: 824px) {
      h1 {
        font-size: 14pt !important;
        word-break: break-all;
      }
    }

    ul.list-group {
      max-width: 300px;
    }

    li.list-group-item {
      cursor: pointer;
      text-overflow: ellipsis;
      button {
        margin-left: 8px;
      }
      &:hover {
        background-color: $gray-9;
        border-color: $gray-9;
      }

      @media (max-width: 824px) {
        word-break: break-all;
      }
    }

    li.list-group-item.selected {
      background-color: $gray-8;
    }

    select.form-control {
      width: auto;
      margin-bottom: 16px;
    }
  }

  .template-picker__details {
    flex: 1;
    margin-left: 20px;
    overflow: auto;
    .panel-heading {
      @include application-text;
      .template-picker__link {
        margin-left: 8px;
        vertical-align: middle;
        cursor: pointer;
      }
      h3.panel-title {
        @include application-text;
      }
      p {
        margin-top: 12px;
        margin-bottom: 0;
      }
    }
    .form-horizontal {
      padding: 16px;
      .btn-toolbar {
        display: flex;
        justify-content: flex-end;
      }
    }
    .table {
      margin-bottom: 0;
    }
  }
}

.template-picker__dialog-wrapper {
  .card-dialog__button-bar {
    margin-bottom: 10px;
  }
}

.dashboard__template-picker {
  &.template-picker__wrapper {
    justify-content: flex-start;
  }

  h2 {
    @include header-font;
    font-size: 18pt;
  }

  .template-picker__list {
    li.list-group-item {
      &:hover,
      &.selected {
        color: $blue-0;
        background-color: $blue-9;
        box-shadow: $blue-highlight-box-shadow;
      }
    }
  }

  .template-picker__details {
    margin-left: 5%;
    padding: 24px;

    .panel-heading {
      .template-picker__link {
        vertical-align: text-bottom;
      }
    }
  }
}

.darkmode {
  .template-picker__wrapper {
    .template-picker__list {
      li.list-group-item {
        &.selected,
        &:hover {
          background-color: #24252c;
        }
      }
    }
  }
}
