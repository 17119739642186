@import 'vars';

.item-list__book-tabs-wrapper {
  & > div {
    @include application-text;
    @include secondary-text;
  }

  .nav-pills {
    & > li > a {
      outline: none !important;
      background: none !important;
      color: rgba(22, 34, 45, 0.7) !important;
    }
    & > li {
      &.active {
        a {
          outline: 2px solid rgba(22, 34, 45, 0.7) !important;
        }
      }
    }
  }
}
