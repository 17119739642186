@import 'vars';

$title_height: 70px;
$title_width: 150px;

$medium_width: 80px;

.line-title__cell {
  width: $title_width;
  border-right: 1px solid $gray-6;
  position: relative;
  margin-left: 5px;
  margin-bottom: 35px;
  display: flex;

  &.medium-timeline {
    width: $medium_width !important;
  }
}
.line-title__line-line {
  position: absolute;
  top: 33px;
  border-top: 3px solid red;
  z-index: 1 !important;
  transition: ease-in-out;
}
.vertical-line-title__line-line {
  position: absolute;
  top: 0;
  left: 98px; // cell width is 146px. padding-left is 25px. (146px / 2) + 25px
  border-right: 3px solid red;
  z-index: 1 !important;
  transition: $cubic;
  &.medium-timeline {
    left: 68px; // cell width is 86px. padding-left is 25px. (86px / 2) + 25px
  }
}

.line-title__body {
  @include plotline-font;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  min-height: $title_height;
  min-width: $title_width;
  padding: 10px;
  transition: all 0.4s $cubic;
  border: 3px dashed transparent;
  word-break: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-group {
    text-align: left;
    .control-label {
      @include secondary-text;
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 0;

      &.darkmode {
        color: $darkmode-text;
      }
    }
  }

  &.medium-timeline {
    min-width: $medium_width;
    padding: 6px;
    @include medium-plotline-font-size;
    .form-group {
      .control-label {
        font-size: 12px;
      }
      input {
        font-size: 12px;
        padding: 6px 2px;
      }
    }
  }

  svg {
    height: 0.7em;
  }
}

.line-title__body.hover {
  @include plotline-font;
  border-top: 3px dashed $blue-7;
  border-left: 3px dashed $blue-7;
  border-bottom: 3px dashed $blue-7;
  // border-right: 3px dashed $blue-7;
  cursor: move;

  &.medium-timeline {
    @include medium-plotline-font-size;
  }
}

.line-title__body.dropping {
  border: $new-item-border;
}

.line-title__hover-options {
  background-color: white;
}

.line-list__insert-beat {
  height: $title_height;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s $cubic;
  font-size: 12px;
  cursor: pointer;
  svg {
    position: absolute;
  }
  .insert-beat-wrapper {
    z-index: 3;

    .glyphicon {
      margin-left: 1px;
    }
  }
  .insert-beat-wrapper.insert-beat-spacer {
    .glyphicon {
      position: relative;
    }
  }
}

.line-list__append-line {
  cursor: pointer;
  .line-list__append-line-wrapper {
    padding: 20px 40px;
    color: $blue-5;
    border: 3px dotted $blue-8;
    margin-left: 5px;
    margin-bottom: 50px;
    text-align: center;
  }
  .line-list__append-line__double {
    margin-left: 5px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;

    div {
      padding: 10px 5px;
      color: $blue-5;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px dashed $blue-8;
      &:hover {
        background-color: $blue-8;
      }
    }
    div.template {
      @include application-text;
      color: $blue-4;
      border-right: none;
      text-align: center;
    }
    div.disabled {
      cursor: $cursor-disabled;
      color: $neutral-gray-5;
      background-color: transparent;
    }
    div.non-template {
      border-left: none;
    }
  }

  &.medium-timeline {
    .line-list__append-line-wrapper {
      padding: 12px 20px;
    }
    .line-list__append-line__double {
      margin-left: 2px;
      flex-direction: column-reverse;
      div {
        padding: 10px 2px;
      }
      div.template {
        font-size: 12px;
        border-top: none;
        border-right: 3px dashed $blue-8;
      }
      div.non-template {
        border-bottom: none;
        border-left: 3px dashed $blue-8;
      }
    }
  }
}

.line-list__append-line:hover {
  color: $blue-5;
  .line-list__append-line-wrapper {
    border: 3px dashed $blue-7;
  }
}

.sticky-table.darkmode {
  .line-list__append-line {
    .line-list__append-line-wrapper {
      color: $blue-7;
    }
    .line-list__append-line__double {
      div.template:not(:hover) {
        color: #aaa;
      }
    }
    .line-list__append-line__double {
      div.disabled:not(:hover) {
        color: $neutral-gray-5;
      }
    }
  }

  .vertical-line-list__append-line__double {
    div.template:not(:hover) {
      color: #aaa;
    }
  }
  .line-list__insert-beat:hover {
    color: $blue-7;
  }
}

//////////////
// Vertical //
//////////////

.vertical-line-title__cell {
  width: 200px;
  min-height: 100px;
  text-align: center;
  display: flex;

  &.medium-timeline {
    min-height: 60px;
    width: 121px;
    text-align: center;
  }
}

.vertical-line-title__body {
  @include plotline-font;
  padding: 20px 10px;
  width: 150px + 6px; // +6px for the 3px border
  margin: auto;
  text-align: center;
  transition: all 0.4s $cubic;
  border: 3px dashed transparent;
  word-break: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-group {
    text-align: left;
    .control-label {
      @include secondary-text;
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 0;
    }
    .control-label.darkmode {
      color: $darkmode-darker-text;
    }
  }

  &.medium-timeline {
    width: 99px;
    padding: 12px 6px;
    margin-left: 22px;
    @include medium-plotline-font-size;
    .form-group {
      .control-label {
        font-size: 12px;
      }
      input {
        font-size: 12px;
        padding: 6px 2px;
      }
    }
  }

  svg {
    height: 0.7em;
  }
}

.vertical-line-title__body.hover {
  border: 3px dashed $blue-7;
  cursor: move;
}

.vertical-line-title__body.dropping {
  border: $new-item-border;
}

.vertical-line-title__hover-options {
  background-color: white;
  .btn-block {
    margin-top: 0;
  }
  &.small-timeline {
    transform: translate(-24px);
  }
}

.vertical-line-title__cell:hover {
  .vertical-line-title__hover-options {
    visibility: visible;
  }
}

.vertical-line-list__insert-beat {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;
  transition: all 0.4s $cubic;
  border: 1px solid transparent;
  font-size: 12px;
  width: 150px;
  cursor: pointer;
  margin: 0 !important;
  margin-left: -5px !important;
  svg {
    position: absolute;
  }
  .vertical-insert-beat-wrapper {
    z-index: 3;
  }
  .vertical-insert-beat-wrapper.insert-beat-spacer {
    display: flex;
    padding: 1px;
    .glyphicon {
      position: relative;
      top: 0;
    }
  }

  &.medium-timeline {
    width: 80px;
    margin-left: 29px;
  }
}

.vertical-line-list__insert-beat:hover {
  color: $blue-5;
  .vertical-insert-beat-wrapper {
    border: 1px dotted $blue-8;
  }
}

.vertical-line-list__append-line {
  height: 100px;
  &.medium-timeline {
    height: 60px;
    width: 110px;
  }
  display: flex;
  align-items: center;
  cursor: pointer;
  .vertical-line-list__append-line-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue-5;
    border: 3px dotted $blue-8;
    width: 135px;
    height: 69px;
    &.medium-timeline {
      width: 120px;
      height: 40px;
    }
  }
  .vertical-line-list__append-line__double {
    display: flex;
    justify-content: space-between;

    div {
      padding: 10px 5px;
      color: $blue-5;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: $blue-8;
      }
    }
    div.template {
      @include application-text;
      color: $blue-4;
      border-right: 3px dashed $blue-8;
      text-align: center;
    }
    div.disabled {
      cursor: $cursor-disabled;
      color: $neutral-gray-5;
      background-color: transparent;
    }
    div.non-template {
      border-left: none;
    }
  }
}

.vertical-line-list__append-line:hover {
  color: $blue-5;
  .vertical-line-list__append-line-wrapper {
    border: 3px dashed $blue-7;
  }
}

.vertical-line-list__insert-beat--small:hover {
  color: $blue-5;
  .vertical-insert-beat-wrapper--small {
    border: 1px dotted $blue-8;
  }
}

.vertical-line-list__append-line--small {
  height: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .vertical-line-list__append-line-wrapper--small {
    padding: 30px $title_height;
    color: $blue-5;
    border: 3px dotted $blue-8;
    margin-right: 50px;
  }
}

.vertical-line-list__append-line--small:hover {
  color: $blue-5;
  .vertical-line-list__append-line-wrapper--small {
    border: 3px dashed $blue-7;
  }
}
