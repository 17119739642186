.restructure-modal__wrapper {
  height: 100%;
  display: grid;
  grid-template-areas: 'header' 'body' 'footer';
  grid-template-rows: auto 1fr auto;
}

.restructure-modal__header {
  grid-area: header;
  div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
  hr {
    margin-top: 8px;
  }
}

.restructure-modal__body {
  grid-area: body;
  height: 50vh;
  overflow-y: auto;
  padding-left: 16px;
  padding-bottom: 16px;
  table {
    // width: 100%;
    height: 100%;

    tr {
      user-select: none;
      &.dragging-over {
        outline: 1px dashed black;
      }
    }
    td {
      padding-right: 64px;
      padding-bottom: 4px;
    }
  }
}

.restructure-modal__footer {
  grid-area: footer;
  & > div {
    display: flex;
    justify-content: space-between;
  }
  hr {
    margin-top: 0;
  }
}

.restructure-modal__select-line {
  margin-left: 5px !important;
  @include user-text;
  button {
    text-transform: capitalize;
  }
}

.restructure-modal__hierarchy-level-selector {
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
    color: $yellow-5;
  }
}

.restructure-modal__title {
  display: flex;
  cursor: move;

  .restructure-modal__grip {
    margin-right: 4px;
    svg {
      color: $gray-8;
      vertical-align: text-top;
    }
  }
}

.darkmode {
  .restructure-modal__body {
    th,
    td {
      color: #bbb;
    }
  }
}
