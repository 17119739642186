@import 'vars';

.place-list {
  h1 {
    text-align: center;
    @include application-text;
  }

  .tab-body {
    width: 99vw;
    overflow: hidden;
    padding-bottom: 4px;

    .row {
      height: 100%;
    }

    .col-sm-3,
    .col-sm-9 {
      height: 100%;
      position: relative;
    }

    .col-sm-9 {
      padding-top: 16px;
    }
  }
}

.place-list__category-list {
  overflow: auto;
  height: calc(100% - 77px);
  position: relative;

  h2 {
    @include sub-header-font;
    font-size: 17px;
  }
}

.place-list__manual-sort {
  display: flex;
  width: 100%;
  justify-content: center;
  color: $yellow-3;
  cursor: pointer;

  &:hover {
    color: $yellow-1;
  }
}

.place-list__category-title {
  word-break: break-all;
}

.list-group-item__wrapper {
  &.dragging {
    transition: 0.01s;
    visibility: hidden;
    height: 0px;
  }

  &:not(.dragging) {
    visibility: visible;
    height: 100%;
  }
}

.place-list__list {
  overflow: auto;
  height: calc(100% - 77px);
  position: relative;
  margin-bottom: 0;

  &.list-group {
    overflow: hidden;
  }

  .dropzone-indicator {
    background-color: transparent;
    transition: height 0.25s linear;
    height: 0px;
    &.display {
      height: 50px;
    }
  }

  .list-group-item {
    padding-right: 4px;
    cursor: grab;

    .place-list__item-inner {
      display: flex;
      align-items: center;
      position: relative;

      .place-list__item-buttons {
        min-width: 71px;
        position: absolute;
        right: 0;
        visibility: hidden;

        &:hover {
          visibility: visible;
        }

        button {
          padding: 4px 8px;
        }
      }

      .place-list__item-inner__image-wrapper {
        img {
          max-width: 75px;
        }

        margin-right: 10px;
      }

      .list-group-item-heading.withImage {
        font-size: 16px;
      }
    }

    &:hover {
      background: $new-item-background;

      .place-list__item-buttons {
        visibility: visible;
      }
    }

    &.selected {
      border: 2px dashed $blue-7;
      background-color: $gray-7;
      margin-bottom: 0;
    }
  }
}

.place-list__list.darkmode {
  .list-group-item {
    cursor: grab;
    background-color: $darkmode-darker-background;
    color: $darkmode-text;
    border-color: $darkmode-darker-border;

    &:hover {
      background: $darkmode-background;
    }

    &.selected {
      border: 2px dashed $darkmode-border;
      background-color: $darkmode-darker-border;
      margin-bottom: 0;
    }

    h6 {
      color: $darkmode-text;
    }

    p {
      color: $darkmode-darker-text;
    }
  }
}

.place-list__new {
  text-align: center;
  cursor: pointer;
  color: $new-item-border-color;
}

.place-list__new:hover {
  background: $new-item-background;
}

.place-list__place-wrapper {
  height: 100%;
  position: relative;
  overflow: auto;
}

.place-list__place-view {
  @include well;
  padding: 20px;
  max-height: 100%;
  overflow: auto;

  display: flex;
  align-items: flex-start;

  .place-list__place-view__left-side {
    width: 18%;
    position: sticky;
    top: 0;
    border-right: 1px solid #eee;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .place-list__place-view__right-side {
    width: 79%;
    height: 100%;
    padding: 4px;
    margin-left: 8px;
    overflow-y: auto;
  }
}

.place-list__place {
  cursor: pointer;

  .place-list__place__edit-form {
    padding-left: 5px;
    display: flex;

    .place-list__inputs__normal {
      width: 50%;
      padding-right: 3%;
      border-right: solid 1px #eee;
    }

    .place-list__inputs__custom {
      width: 50%;
      padding-right: 3%;
      padding-left: 3%;

      .lock-icon__wrapper {
        position: absolute;
        top: 66% !important;
        left: 50%;
      }
    }
  }

  .btn-toolbar {
    display: flex;
    flex-direction: row-reverse;
  }

  .place-list__place-inner {
    border: 2px dashed transparent;
    display: flex;
    justify-content: space-between;
    padding: 4px 0;

    .place-list__left-side {
      max-width: 70%;
    }

    .place-list__right-side {
      display: flex;
      align-items: center;

      .img-responsive {
        min-width: fit-content;
      }

      .glyphicon {
        color: $blue-7;
        visibility: hidden;
        margin: 5px;
      }

      svg {
        margin: auto;
      }
    }

    &:not(.editing) {
      .slate-editor__wrapper {
        .slate-editor__word-counter {
          display: none;
        }
      }
    }
  }

  &:hover {
    .place-list__place-inner {
      border: 2px dashed $blue-7;
      border-radius: 4px;
      background-color: $gray-9;

      .place-list__right-side {
        .glyphicon {
          visibility: visible;
        }
      }
    }
  }
}

.place-list__place-view.darkmode {
  background-color: $darkmode-darker-background;
  border-color: $darkmode-darker-border;

  .place-list__place-wrapper:hover {
    .place-list__place-inner {
      border: 2px dashed $darkmode-border;
      background-color: $darkmode-darker-border;
    }
  }

  h4 {
    color: $darkmode-darker-text;
  }
}

.place-list__place.editing {
  cursor: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .lock-icon__wrapper {
    top: 75%;
    left: 20%;
    font-size: 12px;
    padding: 12px;

    svg {
      font-size: 2rem;
    }
  }

  .place-list__inputs__normal {
    .slate-editor__wrapper {
      position: relative;
      .slate-editor__word-counter {
        display: flex;
      }
    }
  }
}

.place-list__place__edit-image-wrapper {
  display: flex;
  align-items: center;

  .place-list__place__edit-image {
    max-width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.darkmode {
  .place-list__place-view.darkmode {
    background-color: #24252c;
    color: #aaa;
    border-color: $darkmode-v2-border;

    .place-list__place-wrapper:hover {
      .place-list__place-inner {
        border: 2px dashed $darkmode-v2-border;
        background-color: $darkmode-v2-background;
      }
    }

    .place-list__place-view__left-side {
      border-right: 1px solid $darkmode-v2-border;
    }
  }

  .place-list__place {
    .place-list__place__edit-form {
      .place-list__inputs__normal {
        border-right: 1px solid $darkmode-v2-border;
      }
    }

    &.editing {
      background-color: transparent;
    }
  }

  .place-list__list.darkmode {
    .list-group-item {
      background-color: $darkmode-v2-background;
      color: $darkmode-v2-darker-text;
      border-color: $darkmode-v2-border;

      &.selected {
        border: 1px dashed #aaa;
        background-color: #24252c;
      }

      h6 {
        color: $darkmode-v2-text;
      }
    }
  }
}
