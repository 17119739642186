@import 'vars';

.tag-list__container {
  h1 {
    text-align: center;
    @include application-text;
    width: 99vw;
    margin-left: 0.5vw;
  }

  .tag-list__wrapper {
    display: flex;
    justify-content: flex-start;
    width: 99vw;
    margin: auto;
    overflow: auto;
  }
}

.tag-list__new {
  @include well;
  padding: 6px 12px;
  height: 58px;
  margin-top: 46px; // hover-options on other tags are 36px + margin
  cursor: pointer;
  width: 200px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.tag-list__new.darkmode {
  background-color: $darkmode-darker-background;
  color: $darkmode-text;
  border-color: $darkmode-border;

  &:hover {
    background-color: $darkmode-background;
  }
}

.tag-list__new:hover {
  background: $new-item-background;
}

.tag-list__tags {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

.tag-category-list {
  display: flex;
  flex-flow: wrap;
}

.tab-body__title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;

  h1 {
    width: auto;
    margin: 10px;
  }
}

.tag-list__tag-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3px;
}

.tag-list__category-wrapper {
  margin: 20px;
  display: flex;
  flex-flow: wrap;
  width: 100%;

  h2 {
    @include sub-header-font;
    display: flex;
    width: 100%;
    margin-bottom: -26px;
    margin-top: 10px;
    font-size: 17px;
  }
}

.tag-list__tag {
  @include well;
  width: 200px; // 5 per row
  padding: 7px 14px;

  &:not(.editing):hover {
    background-color: $gray-8;
  }
}

.tag-list__tag.darkmode {
  background-color: $darkmode-darker-background;
  color: $darkmode-text;
  border-color: $darkmode-border;

  &:not(.editing):hover {
    background-color: $darkmode-background;
  }
}

.tag-list__tag__hover-options {
  margin: 5px 0;
}

.tag-list__tag-normal {
  cursor: pointer;
  word-break: break-word;
  overflow-wrap: break-word;
}

.tag-list__color-edit-container {
  display: flex;

  .tag-list__tag-color {
    width: 40px;
    height: 38px;
    float: right;
    margin: 3px;
    border-color: #ccc;
    border-radius: 4px;
  }
}

.tag-list__color-label {
  margin-top: 5px;

  .label {
    vertical-align: middle;
    padding-top: 5px;
  }
}

.tag-list__tag__button-bar {
  display: flex;
  justify-content: flex-end;
}

.darkmode {
  .tag-list__wrapper {
    background-color: transparent;

    .tag-category-list {
      .tag-list__category-wrapper {
        background-color: transparent;
      }
    }
  }

  .tag-list__tag.darkmode {
    background-color: #24252c;
    color: $darkmode-v2-darker-text;
    border-color: $darkmode-v2-border;
    transition: 0.2s;

    &:not(.editing):hover,
    &:hover {
      background-color: $darkmode-v2-background;
    }

    .tag-list__tag-normal {
      h6 {
        color: $darkmode-v2-darker-text;
      }
    }
  }

  .tag-list__new.darkmode {
    background-color: #24252c;
    color: $darkmode-v2-darker-text;
    border-color: $darkmode-v2-border;
    transition: 0.2s;
  }

  .tag-list__tag__hover-options {
    .btn-group {
      .btn,
      .btn-default,
      .btn-warning,
      .btn-danger {
        background-color: $darkmode-v2-background;
        border-color: #4d4d4f;

        &:hover {
          background-color: $darkmode-v2-card-background;
        }
      }

      .btn-warning {
        .glyphicon {
          color: #bbb;
        }

        &:hover {
          background-color: #bfa93f !important;
          .glyphicon {
            color: $darkmode-v2-text;
          }
        }
      }

      .btn-danger {
        .glyphicon {
          color: #bbb;
        }

        &:hover {
          background-color: #bd3755 !important;
          .glyphicon {
            color: $darkmode-v2-text;
          }
        }
      }
    }
  }
}
