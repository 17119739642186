.relationship-editor__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.relationship-editor__body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 270px;
}

.relationship-editor__left-side {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
  padding-right: 20px;
  border-right: 1px solid $gray-9;
  overflow-y: auto;
}

.relationship-editor__item-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
}

.relationship-editor__item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 32.844px;
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  cursor: pointer;
  border: 1px solid $gray-9;
  border-radius: 10px;
  border: 1px solid;
  user-select: none;
  &:hover {
    background: $gray-9;
  }
  &.selected {
    background: $gray-9;
  }
}

.relationship-editor__right-side {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-left: 20px;
}

.relationship-editor__right-side__smaller-controls-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.relationship-editor__right-side__smaller-controls-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  padding-right: 10px;
}

.relationship-editor__right-side__smaller-controls-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50%;
  padding-left: 10px;
  margin-bottom: 20px;
}
