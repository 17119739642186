@import 'vars';

.timeline-tabbed-view-body {
  display: flex;

  .tablist {
    overflow-y: hidden;
  }
}

.nav-with-cross,
.nav-context-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
}

#timelineview__root {
  width: 100vw;

  &.timeline-tabbed-view-body {
    position: relative;
  }

  .sticky-table {
    user-select: none;
  }

  .sticky-table-table {
    white-space: normal;
    transform-origin: left top;
  }

  .sticky-table-cell {
    padding: 0;
    background-color: $body-background-color;
    vertical-align: top;
    border: none;
  }

  .sticky-table-cell:hover {
    .line-list__insert-beat {
      color: $blue-5;
    }
  }

  .sticky-table-row:last-child {
    display: contents;
  }

  .sticky-table.darkmode {
    background-color: $darkmode-card-background;
    color: $darkmode-text;

    .sticky-table-row {
      background-color: $darkmode-card-background;
      color: $darkmode-text;
    }

    .sticky-table-cell {
      background-color: $darkmode-card-background;
      color: $darkmode-text;
    }
  }

  // vertical orientation, border under the plotline titles
  .sticky-table.vertical {
    .sticky-table-cell {
      vertical-align: middle;

      &.medium-timeline {
        vertical-align: top;
      }
    }

    .sticky-table-cell:has(.card-pinned) {
      z-index: 3;

      > .vertical-line-title__line-line {
        z-index: 2;
      }
    }

    .sticky-table-cell:has(.vertical-blank-card__body.card-pinned),
    .sticky-table-cell.card-pinned,
    .sticky-table-cell:has(.card__cell.vertical.card-pinned) {
      z-index: 10 !important;
    }

    .sticky-table-row:first-child {
      .sticky-table-cell:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 25px;
        width: 75%;
        padding-top: 1px;
        border-bottom: 1px solid $gray-6;
      }

      .sticky-table-cell:first-child:after {
        border: none;
      }
    }
  }

  .nav-tabs {
    width: 100vw;
    display: flex;
    flex-direction: row;
    overflow-x: auto;

    li {
      max-height: 44px;
      min-width: max-content;

      span.glyphicon {
        font-size: 13px;
      }

      a {
        border-radius: 0;
        border-top: none;
        outline: none;
        color: $text-color;
        min-width: 145px;

        div {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          svg {
            opacity: 0;
          }

          &:hover {
            svg {
              opacity: 1;
            }
          }
        }
      }

      &.active {
        a {
          color: $link-color;
        }

        div {
          svg {
            opacity: 1;
          }
        }
      }

      &.context-menu-open {
        div {
          svg {
            opacity: 1;
          }
        }
      }
    }
  }

  .sticky-table-cell.card-pinned:has(.vertical-line-title__line-line) {
    z-index: 8;
  }

  .sticky-table-cell.card-pinned:has(.card__body-wrapper, .card__behind, .creating) {
    z-index: 11;
  }

  .sticky-table-cell:has(.card__cell.card-pinned .creating, .card__cell.card-pinned
      .blank-card__body.card-pinned) {
    z-index: 10;
  }

  .sticky-table-cell.card-pinned:has(.vertical-insert-beat-wrapper:not(.transparent)) {
    z-index: 11;
  }
}

.tab-dropdown-menu {
  min-width: 160px;
  padding: 5px 0;
  margin: 0; // override default ul
  list-style: none;
  font-size: $font-size-base;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  background-color: $dropdown-bg;
  border: 1px solid $dropdown-fallback-border; // IE8 fallback
  border: 1px solid $dropdown-border;
  border-radius: $border-radius-base;
  @include box-shadow(0 6px 12px rgba(0, 0, 0, 0.175));
  background-clip: padding-box;

  // Links within the dropdown menu
  > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: $line-height-base;
    color: $dropdown-link-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines

    &:hover,
    &:focus {
      text-decoration: none;
      color: $dropdown-link-hover-color;
      background-color: $dropdown-link-hover-bg;
    }
  }
}

.view-selector__select-line {
  @include user-text;

  button {
    font-size: 14px;
    height: 33px;
    padding: 7px;
    text-transform: capitalize;
  }
}

.view-selector__timeline-view-selector {
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    color: $yellow-5;
  }
}

// important that this is here (not nested)
// if not, it breaks the sticky columns/rows in the timeline
.sticky-table-cell {
  position: relative;
}

.scene-card-update-toast {
  position: fixed;
  bottom: 20px !important;
  right: 20px !important;
  max-width: 100%;
  z-index: 1001 !important;
  display: flex;
  justify-content: flex-end;
}

////////////////////
// small timeline //
////////////////////
#timelineview__root {
  .small-timeline__wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .table-header-rotated {
    margin-left: 2px;
  }

  /////////////////////
  // copied and modified from https://www.jimmybonney.com/articles/column_header_rotation_css/
  /////////////////////

  .table-header-rotated th.row-header {
    padding-left: 8px;
    width: 58px; // 50 + 8
    min-width: 58px; // 50 + 8
    background-color: $gray-9;
    border-top: 1px solid $gray-8;
    border-left: 1px solid $gray-8;
    position: sticky;
    left: 0;
    font-size: 12px;
    cursor: move;

    .line-list__append-line {
      .line-list__append-line-wrapper {
        padding: 2px 0;
        border: none;
        margin: 0;

        &:hover {
          border: 2px dotted $blue-7;
          background-color: $blue-8;
        }
      }
    }

    &.dropping {
      & > div {
        border: $new-item-border;
      }
    }

    svg {
      height: 0.7em;
    }
  }

  .table-header-rotated tr {
    &:last-child {
      border-bottom: 1px solid $gray-8;

      td {
        border-style: solid;
        border-color: $gray-8;
        border-width: 1px;
        border-top: 1px solid $gray-8;
      }
    }
  }

  .table-header-rotated td {
    width: 20px;
    border-top: 1px solid $gray-8;
    border-right: 1px solid $gray-8;
    vertical-align: middle;
    text-align: center;
    padding: 2px 0;

    &:last-child {
      border-style: solid;
      border-width: 1px;
      border-color: $gray-8;
    }

    .div-card-circle {
      height: 10px;
      width: 10px;
      border-radius: 10px;
      background-color: black;
      margin: 1px auto;
      cursor: pointer;

      .card__title {
        @include scenecard-title-font;
        padding: 6px;
      }
    }

    div.card-circle {
      @extend .div-card-circle;
    }

    div.blank-circle {
      @extend .div-card-circle;
      visibility: hidden;
      background-color: transparent;

      &.hover {
        visibility: visible;
        border-width: 1px;
        border-style: dotted;
      }
    }

    .card__drop-zone {
      font-size: 8px;
    }

    &:nth-child(2) {
      border-left: 1px solid $gray-8;
    }

    &:hover {
      div.blank-circle {
        visibility: visible;
        border-width: 2px;
        border-style: dotted;
      }
    }
  }

  .table-header-rotated thead {
    th:first-child {
      background-color: $gray-9;
      position: sticky;
      top: 0;
      z-index: 10;
    }
  }

  .table-header-rotated th.rotate-45 {
    height: 40px;
    // height: 80px;
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    vertical-align: bottom;
    padding: 0;
    font-size: 12px;
    line-height: 0.8;
    position: sticky;
    top: 0;

    &.dropping {
      & > div {
        border: 1px dotted #aaa;
      }
    }

    &:nth-child(2) {
      background-color: $gray-9;
      z-index: 10;

      & > div {
        background-color: $gray-9;
        border-left: 1px solid $gray-8;
      }
    }

    &:nth-child(3) {
      background-color: $gray-9;
    }

    & > div {
      position: relative;
      top: 0px;
      left: 21px;
      // left: 40px; // 80 * tan(45) / 2 = 40 where 80 is the height on the cell and 45 is the transform angle
      height: 100%;
      transform: skew(-45deg, 0deg);
      overflow: hidden;
      background-color: $gray-9;
      border-right: 1px solid $gray-8;
      border-top: 1px solid $gray-8;
      cursor: move;

      &.beat-list__insert.append-beat {
        font-size: 8px;
        cursor: pointer;

        div {
          padding: inherit;
          border: none;
          margin-right: 0;

          & > span {
            transform: translate(5px) skew(45deg, 0deg);
          }
        }

        &:hover {
          border: 2px solid $blue-7;
          background-color: $blue-8;
        }
      }

      & > span {
        transform: skew(45deg, 0deg) rotate(315deg);
        font-size: 8px;
        position: absolute;
        bottom: 30px; // 40 cos(45) = 28 with an additional 2px margin
        left: -32px;
        // left: -25px; // Because it looked good, but there is probably a mathematical link here as well
        display: inline-block;
        // width: 100%;
        width: 85px; // 80 / cos(45) - 40 cos (45) = 85 where 80 is the height of the cell, 40 the width of the cell and 45 the transform angle
        text-align: left;
        white-space: nowrap;
        /*whether to display in one line or not*/
      }
    }
  }

  .small-timeline__wrapper.darkmode {
    color: $darkmode-text;

    .table-header-rotated thead {
      th:first-child {
        background-color: $darkmode-background;
      }
    }

    .table-header-rotated th.row-header {
      background-color: $darkmode-background;
    }

    .table-header-rotated th.rotate-45 {
      &:nth-child(2) {
        background-color: $darkmode-background;
      }

      &:nth-child(3) {
        background-color: $darkmode-background;
      }

      & > div {
        background-color: $darkmode-background;
      }
    }
  }

  .small-timeline__wrapper.vertical {
    padding-bottom: 4px;

    .table-header-rotated {
      margin-left: 20px;

      th.row-header {
        padding-left: 16px;
        padding-right: 16px;
        width: 102px; // 70 + 16 + 16
        min-width: 102px; // 70 + 16 + 16
        text-align: right;

        .vertical-beat-list__insert.append-beat {
          height: unset;
          width: unset;
          margin: 0;
          font-size: 8px;

          .vertical-insert-beat-wrapper.append-beat {
            padding: 2px 0;
            border: none;
            margin: 0;
          }

          &:hover {
            border: 2px solid $blue-7;
            background-color: $blue-8;
          }
        }
      }

      th.rotate-45 {
        .vertical-line-list__append-line--small {
          cursor: pointer;
          font-size: 8px;

          .vertical-line-list__append-line-wrapper--small {
            padding: 0;
            margin: 0;
            border: none;
            transform: translate(5px) skew(45deg, 0deg);
          }

          &:hover {
            border: 2px solid $blue-7;
            background-color: $blue-8;
          }
        }
      }
    }
  }

  .timeline__tab {
    width: 100vw;
    height: max-content;
    overflow: auto;

    .sticky-table,
    .small-timeline__wrapper {
      position: absolute;
      width: 100%;
    }
  }

  .timeline__tabs-wrapper {
    & > div {
      @include application-text;
      @include secondary-text;
    }

    .nav-pills {
      & > li {
        svg {
          opacity: 0;
        }

        &:hover {
          svg {
            opacity: 1;
          }
        }

        outline: none;
        margin: 10px;
      }

      & > li > a {
        outline: none;
        padding: 10px;
        background: none !important;
        color: rgba(22, 34, 45, 0.7) !important;
      }

      & > li {
        &.active {
          svg {
            opacity: 1;
          }

          a {
            outline: 2px solid rgba(22, 34, 45, 0.7) !important;
          }
        }
      }
    }
  }
}

#timelineview__root
  .isPinned
  .sticky-table-cell:not(:first-child):not(:nth-child(2)):not(:has(.card__body-wrapper, .card__cell__overview-cell, .creating, .card__cell.card-pinned)) {
  z-index: 3;

  &:hover {
    z-index: 5;
  }
}

#timelineview__root
  .isPinned
  .sticky-table-cell:not(:first-child):not(:nth-child(2)):not(:has(.card__body-wrapper, .card__cell__overview-cell, .card__cell.card-pinned)) {
  z-index: 3;

  &:hover {
    z-index: 5;
  }
}

.darkmode {
  #timelineview__root {
    // Tabbed
    .timeline-tabbed-view-body {
      ul.nav.nav-tabs.-nav {
        background: $darkmode-v2-background;
      }
    }
    .nav-tabs {
      background: $darkmode-v2-background;
      border-bottom: solid 1px $darkmode-v2-border;

      li:last-child {
        a {
          border: none !important;
        }
      }

      li {
        a {
          margin: 0 !important;
        }
      }
    }

    li a {
      color: #aaa;
    }

    li.active a {
      color: #ccc;
    }

    > li > a {
      color: #aaa;
    }

    .sticky-table.darkmode {
      background-color: $darkmode-v2-card-background;
      color: $darkmode-v2-darker-text;

      .sticky-table-row {
        background-color: $darkmode-v2-card-background;
      }

      .sticky-table-cell {
        background-color: $darkmode-v2-card-background;
        color: #ccc;
      }

      .sticky-table-cell,
      .card__cell,
      .card__cell__overview-cell,
      .card__cell .card__body,
      .card__cell__overview-cell .card__body,
      .card__cell__overview-cell .blank-card__body,
      .card__cell .vertical-blank-card__body,
      .card__cell__overview-cell .vertical-blank-card__body,
      .card__cell .blank-card__body {
        background-color: $darkmode-v2-card-background;
      }
    }

    .sticky-table-table {
      background-color: $darkmode-v2-card-background;
    }

    .small-timeline__wrapper.darkmode {
      color: $darkmode-v2-darker-text;
      background: $darkmode-v2-card-background;

      .table-header-rotated {
        th.rotate-45 > div {
          background-color: $darkmode-v2-card-background;
        }

        th.row-header,
        thead th:first-child,
        th.rotate-45:nth-child(2),
        th.rotate-45:nth-child(3) {
          background-color: $darkmode-v2-card-background;
        }
      }
    }

    .table-header-rotated {
      td {
        border-top: 1px solid #bbb;
        border-right: 1px solid #bbb;
      }

      th.rotate-45 > div {
        background-color: #f1f5f8;
        border-right: 1px solid #bbb;
        border-top: 1px solid #bbb;
      }
    }
  }
}
