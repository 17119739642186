@import 'vars';

.center-modal-vertically {
  // 70px is half of dialog height
  // 3 to get it just above the middle
  margin-top: calc(calc(100vh - 70px) / 3);
}

.input-modal__body-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.input-modal__controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.input-modal__controls__control {
  margin-left: 4px;
}

.modal-body {
  word-break: break-word;
}

.ReactModal__Content--after-open {
  top: 60px;
  border-radius: 25px;
  box-shadow: 0 6px 24px 0 rgba(18, 43, 70, 0.12) !important;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.1) !important;
  z-index: 1000;
}

.modal-container {
  position: relative;
}
.modal-container .modal,
.modal-container .modal-backdrop {
  position: absolute;
}

.no-scroll__modal {
  overflow-y: hidden !important;
}

.darkmode {
  .ReactModal__Content--after-open {
    border-color: $darkmode-v2-border !important;
  }

  .center-modal-vertically {
    .modal-content {
      border: 1px solid $darkmode-v2-border;
    }
  }
}
