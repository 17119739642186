@import '../colors';
@import '../vars';

@keyframes dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.update-notifier {
  @include application-text;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 20px;
  margin-bottom: 0;
  z-index: 10;

  .update-notifier__buttons {
    display: flex;
    align-items: center;

    .btn {
      border-radius: 100px;
    }
    button.close {
      opacity: 0.4;
    }
  }

  .progress {
    width: 50vw;
    margin-bottom: 0;
  }

  svg {
    margin-right: auto;
    margin-left: 8px;
  }

  &.floating {
    position: absolute;
    top: 50px;
    right: 10px;
    margin: 0;
  }

  .loading-dots {
    .one {
      opacity: 0;
      animation: dot 1s infinite;
      animation-delay: 0s;
    }

    .two {
      opacity: 0;
      animation: dot 1s infinite;
      animation-delay: 0.2s;
    }

    .three {
      opacity: 0;
      animation: dot 1s infinite;
      animation-delay: 0.3s;
    }
  }
}
