.image-circle {
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0 2px 2px 0 hsla(0, 0%, 0%, 0.1), inset 0 -2px 2px 0 hsla(0, 0%, 0%, 0.1);
}

.image-circle-xl {
  @extend .image-circle;
  flex-shrink: 0;
  height: 150px;
  width: 150px;
}

.image-circle-large {
  @extend .image-circle;
  flex-shrink: 0;
  height: 100px;
  width: 100px;
}

.image-circle-small {
  @extend .image-circle;
  flex-shrink: 0;
  height: 75px;
  width: 75px;
}

.image-circle-xs {
  @extend .image-circle;
  flex-shrink: 0;
  height: 25px;
  width: 25px;
}

.image-rounded {
  background-size: cover;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px 0 hsla(0, 0%, 0%, 0.1), inset 0 -2px 2px 0 hsla(0, 0%, 0%, 0.1);
}

.image-rounded-xl {
  @extend .image-rounded;
  height: 300px;
  width: 300px;
}

.image-rounded-large {
  @extend .image-rounded;
  height: 200px;
  width: 200px;
}

.image-rounded-small {
  @extend .image-rounded;
  height: 75px;
  width: 75px;
}

.image-rounded-xs {
  @extend .image-rounded;
  height: 25px;
  width: 25px;
}

.image-square {
  background-size: cover;
  box-shadow: inset 0 2px 2px 0 hsla(0, 0%, 0%, 0.1), inset 0 -2px 2px 0 hsla(0, 0%, 0%, 0.1);
}

.image-square-xl {
  @extend .image-square;
  height: 300px;
  width: 300px;
}

.image-square-large {
  @extend .image-square;
  height: 200px;
  width: 200px;
}

.image-square-small {
  @extend .image-square;
  height: 75px;
  width: 75px;
}

.image-square-xs {
  @extend .image-square;
  height: 25px;
  width: 25px;
}
