// DARK GREY - Sidebar background, Fill in Fields, Table Header background, Boxes behind the Top icons/Nav in the Files Tab.
$dark-gray-1: #444444;
// TABLE Row Color 1:
$dark-gray-2: #555555;
// LIGHTER GREY - Active tab, Background dashboard
$dark-gray-3: #666666;
// TABLE ROW COLOR 2:
$dark-gray-4: #6a6a6a;
// TOGGLE GREY - Pasive state, FOLDER ICONS on Backups Tab:
$dark-gray-5: #9d9898;
// Coming Soon Font Color:
$dark-gray-6: #abafb5;
// SIDEBAR ICONS and FILE TOP NAV ICONS, Backups Folder Icons, HELP TAB - Light Buttons Grey:
// Regular Font Color + Fill In Fields text color:
$dark-gray-7: #dbe2ec;

$dark-orange: #ff7f32;
$dark-text: $dark-gray-7;
$dark-link: #c9e6ff;
$dark-link-active: #002057;
$white-highlight-box-shadow: inset 0px -1px 1px 0px rgba(0, 0, 0, 0.1),
  inset 0 -1px 5px 0 $dark-gray-7, inset 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
  inset 0 1px 5px 0 $dark-gray-7;

.dashboard__header.darkmode {
  background-color: $dark-gray-1 !important;
  background-image: none !important;
  color: $dark-text;

  .nav-3right-buttons svg:hover {
    background-color: $dark-gray-3 !important;
  }

  .beamer-wrapper #beamer-bell svg {
    color: $dark-text !important;
  }
}

.dashboard__main.darkmode {
  background-color: $dark-gray-3 !important;
  color: $dark-text;

  .secondary-text {
    color: $dark-text;
  }

  .beamer-wrapper #beamer-bell svg {
    color: $dark-text !important;
  }

  hr {
    border-color: $darkmode-border;
  }

  ////////////////
  // navigation //
  ////////////////
  nav {
    background-color: $dark-gray-1 !important;
  }

  .dashboard__navigation-tab {
    background-color: $dark-gray-1;
    border-bottom-color: $dark-gray-3;
    color: $dark-text;

    &:first-child {
      border-top-color: $dark-gray-1;
    }

    &:hover {
      background-color: $dark-gray-2;
    }

    &.selected {
      background-color: $dark-gray-3;

      &:first-child {
        border-top-color: $dark-gray-3;
      }

      svg {
        color: $dark-orange;
      }
    }
  }

  /////////////
  // account //
  /////////////
  .dashboard__about__wrapper {
    dd {
      a {
        color: $dark-link;
      }
    }
  }

  .dashboard__account {
    .verify__choice {
      color: $dark-text !important;
    }
  }

  ///////////
  // files //
  ///////////
  .dashboard__new-files__item {
    color: $dark-text;
    background: $dark-gray-1;

    &.icon {
      svg {
        color: $dark-gray-5;
      }
    }

    &:hover,
    &.active {
      background-color: $dark-gray-2;
      box-shadow: $white-highlight-box-shadow;
    }
  }

  .dashboard__recent-files .dashboard__search {
    background-color: $dark-gray-1;
    border-color: $dark-gray-2;
    // &::-webkit-input-placeholder {
    //   color: $dark-text;
    // }
  }

  .dashboard__recent-files__table {
    .sticky-table-table {
      .sticky-table-row:first-child .sticky-table-cell {
        .sticky-table-row:first-child .sticky-table-cell {
          background-color: $darkmode-v2-darker-background !important;
          color: $darkmode-v2-darker-text;
        }
      }

      .sticky-table-row:nth-of-type(even) .sticky-table-cell {
        background-color: #404350 !important;
      }

      .sticky-table-cell {
        color: #ccc;
      }

      .sticky-table-row:not(:first-child) {
        .sticky-table-cell,
        .sticky-table-cell:not(:first-child) {
          border-left: none;
        }
      }

      .dashboard__recent-files__file-cell {
        .secondary-text,
        .sub-header {
          color: #ccc;
        }
      }

      .sticky-table-row:first-child {
        .sticky-table-cell:first-child,
        .sticky-table-cell:nth-child(2) {
          border-right: 1px solid #4b4d4f;
        }

        .sticky-table-cell:first-child {
          border-right-color: transparent;
        }

        .sticky-table-cell:nth-child(2) {
          border-right: transparent;
        }
      }

      .sticky-table-row:nth-of-type(odd):not(:first-child) .sticky-table-cell {
        background-color: #363945 !important;
      }
    }
  }

  .template-picker__wrapper {
    .template-picker__list {
      li.list-group-item {
        color: $darkmode-v2-darker-text;
        border-color: $darkmode-v2-border;
        background-color: $darkmode-v2-background;

        &.selected,
        &:hover,
        &:focus {
          background-color: #24252c;
          border: 1px solid $darkmode-v2-border;
        }
      }
    }

    .template-picker__details {
      .panel-body {
        background-color: $dark-gray-4;

        button {
          color: $dark-text;
          background-color: $dark-gray-1;
          border-color: $dark-text;

          &:hover {
            background-color: $dark-gray-2;
          }
        }
      }
    }
  }

  /////////////
  // backups //
  /////////////
  .dashboard__backups__projects-summary {
    color: $dark-text;
    background-color: $darkmode-v2-background;
    .count-circle {
      border-color: white;
    }
    &.active {
      background-color: $darkmode-v2-darker-background;
    }
  }
  .dashboard__backups__columns {
    color: $dark-text;
  }
  .dashboard__backups__project-row {
    &.row {
      background-color: transparent;
    }
    .dashboard__backups__project-backup .dashboard__backups__item {
      .dashboard__backups__item-details small {
        color: $dark-gray-6;
      }
    }
  }

  //////////////
  // settings //
  //////////////
  .dashboard__options {
    h1 {
      &.secondary-text {
        color: $dark-gray-6;
      }
    }

    .option-switch-label:not(.checked) {
      background-color: $dark-gray-6;
    }

    .option-switch-labeltext {
      color: $dark-text;
    }

    div#settings-tabs {
      .nav-tabs {
        border-bottom-color: $darkmode-border;
      }

      .nav-tabs > li:not(.active) > a:not(:hover) {
        color: $darkmode-link-text;
        border-bottom-color: $darkmode-border;
      }

      .tab-content {
        border-color: $darkmode-border;

        .card-dialog__custom-attributes-configuration-link {
          color: $darkmode-link-text;
        }
      }
    }
  }

  .dashboard__options__item {
    select {
      color: #bbb;
    }

    input {
      color: $dark-gray-1;
    }

    .help-block {
      color: $dark-text;
    }

    .btn-link {
      color: $dark-link;

      &:hover,
      &:focus {
        color: $dark-link-active;
      }
    }

    &.disabled {
      color: $dark-gray-6;

      .help-block {
        color: $dark-gray-6;
      }

      .form-control {
        background-color: $dark-gray-1;
        color: $dark-gray-6;
      }
    }
  }

  //////////
  // help //
  //////////
  .dashboard__help {
    webview {
      border-color: $darkmode-border;
    }
  }

  .dashboard__help__item {
    &.links {
      button.btn-link {
        color: $dark-link;

        &:hover,
        &:focus {
          color: $dark-link-active;
        }
      }
    }

    &.actions {
      #customerServiceCode {
        background-color: $dark-gray-1;
        border-color: $dark-gray-2;
      }
    }
  }
}

/////////////////////
// update notifier //
/////////////////////

.update-notifier {
  &.alert {
    &.darkmode {
      color: $dark-text;
      background-color: $dark-gray-1;
      border-color: $dark-text;
    }
  }
}

body.darkmode {
  background-color: $darkmode-v2-background;

  .dashboard__main.darkmode {
    background-color: $darkmode-v2-card-background !important;
    color: #ddd !important;

    .dashboard__recent-files .dashboard__search {
      background-color: $darkmode-v2-background;
      border-color: $darkmode-v2-border;
    }

    .dashboard__help__item {
      &.actions {
        #customerServiceCode {
          background-color: $darkmode-v2-background;
          border-color: $darkmode-v2-border;
        }
      }
    }

    nav {
      background-color: $darkmode-v2-darker-background !important;
    }

    .dashboard__options {
      div#settings-tabs {
        .nav-tabs {
          color: #bbb;
          border-bottom-color: $darkmode-v2-nav-tabs-border;

          > li:not(.active) > a:not(:hover) {
            color: #bbb;
            border-bottom-color: $darkmode-v2-nav-tabs-border;
          }
        }
      }
    }
  }

  /////////////////////
  // settings Wizard //
  /////////////////////

  .settings-wizard__wrapper {
    .onboarding__step {
      background-color: $darkmode-v2-darker-background;
      .step__body {
        .option-switch-labeltext {
          color: $darkmode-v2-darker-text;
        }
        .help-block {
          color: $darkmode-v2-darker-text;
        }
      }
    }
  }

  .onboarding__progress__titles {
    > div {
      color: $darkmode-v2-darker-text;
    }
  }
}
