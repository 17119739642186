@import '../colors';

#dashboard__react__root {
  #spinner {
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .verify__wrapper {
    #alert {
      padding: 10px;
      margin-top: 5px;

      &.bg-danger {
        color: $state-danger-text;
      }

      &.bg-success {
        color: $state-success-text;
      }
    }

    .btn {
      margin-left: 7px;
    }
  }

  h1.verify {
    font-family: 'Yellowtail' !important;
    font-weight: 400 !important;
    font-size: 36pt !important;
  }
  img.verify {
    vertical-align: -50%;
  }

  .verify__chooser {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 32px;
  }

  .verify__choice {
    width: 35%;
    height: 140px;
    padding: 10px;
    border: 3px solid $blue-7;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.4s $cubic;
    color: $gray-1;
  }

  .verify__choice:hover {
    transform: scale(1.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
  }
}
