@import 'vars';
@import 'colors';

.error-boundary {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    font-size: 28pt;
    color: $yellow-5;
    vertical-align: top;
    margin-right: 4px;
  }
  h1 {
    font-size: 24pt;
    margin-top: 8px;
  }
  h2 {
    font-size: 20pt;
    margin-top: 8px;
  }

  &.rce {
    svg {
      font-size: 20pt;
    }
  }
}

.error-boundary__options {
  display: flex;
  justify-content: space-evenly;
  width: 30%;
  margin: 16px 0;
}

.error-boundary__view-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60%;
}

body.darkmode {
  .error-boundary-title,
  .error-boundary-body {
    color: #aaa;
    background-color: transparent;
  }
}
