$font-size: 20px;

.home {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: hsl(60, 3%, 94%) url('/mountain_background.jpg');
  background-size: cover;
  background-blend-mode: overlay;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home__main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.home__left {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  width: 50%;
  height: 100%;
  display: flex;
}

.home__right {
  width: 50%;
}

.home__controls {
  width: 400px;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.home__button {
  height: 40px;
  font-size: $font-size;
  line-height: $font-size;
  padding: 10px;
  background-color: $orange;
  color: white;
  border-radius: 5px;
}

.home__button:hover,
.home__button:focus {
  color: white;
}

.home__logo {
}
