@import 'vars';

.color-picker__title {
  margin: 0px;
  margin-bottom: 10px;
}

.color-picker__input-box {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    .form-group .control-label {
      text-align: left;
    }

    .col-xs-2,
    .col-xs-1,
    .col-xs-5 {
      padding: 0 !important;
      width: 100%;
      display: flex;
      margin: 0 !important;
      margin-left: 16px !important;
    }

    .col-xs-2 {
      p {
        margin-top: 0 !important;
      }

      button {
        margin-bottom: 18px;
      }
    }
  }
}

.color-picker__show-color {
  display: inline-block;
  height: 50px;
  width: 60px;
  border-radius: 10px 0px 10px 0px;
  margin-right: 7px;
  vertical-align: middle;
  background-color: white;
  border: 1px solid #eee;

  @media (max-width: 1000px) {
    margin: 0 !important;

    .form-group .control-label {
      text-align: left;
    }
  }
}

@media (max-width: 1000px) {
  .form-horizontal .form-group {
    width: 100%;
    margin: 0 !important;
    margin-top: 10px !important;

    .form-control {
      width: 60%;
    }
  }
}

.color-picker__box-wrapper {
  p {
    @include header-font;
    font-size: 20pt;
    margin-top: 10px;
  }
}

.color-picker__box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.color-picker__wrapper.darkmode {
  background-color: $darkmode-card-background;

  .color-picker__title {
    color: $darkmode-text;
  }

  h5.secondary-text {
    color: $darkmode-darker-text;
  }

  p {
    color: $darkmode-text;
  }
}

.mini-color-picker {
  z-index: 9999;
  width: 200px;
  height: 200px;
  overflow: scroll;
  background-color: white;
  border: 1px solid $gray-9;
  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.15);
  padding: 4px;

  p {
    margin: 0;
  }

  .color-picker__box {
    .color-picker__choice {
      margin: 1px;

      button {
        height: 20px;
        width: 12px;
        border-radius: 4px 0px 4px 0px;
      }

      button::after {
        border-radius: 4px 0px 4px 0px;
      }
    }
  }

  &.darkmode {
    background-color: $darkmode-card-background;
    border-color: $darkmode-darker-text;
  }
}

.color-picker__choice {
  margin: 3px;

  button {
    height: 50px;
    width: 60px;
    border-radius: 10px 0px 10px 0px;
    border-width: 0px;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  button::after {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 10px 0px 10px 0px;
    border-width: 0px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  button:hover {
    transform: scale(1.25, 1.25);
  }

  button:hover::after {
    opacity: 1;
  }
}

.darkmode {
  .color-picker__input-box {
    border-top: 1px solid #4d4d4f;
    border-bottom: 1px solid #4d4d4f;
    margin-bottom: 10px;
  }
}
