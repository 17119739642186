@import 'vars';

.character-list__full-screen-family-tree-container {
  margin: 20px;
}

.character-list {
  h1 {
    text-align: center;
    @include application-text;
    @include header-font;
  }

  .tab-body {
    width: 99vw;
    overflow: hidden;

    .row {
      height: 100%;
    }

    .col-sm-3,
    .col-sm-9 {
      height: 100%;
      position: relative;
    }

    .col-sm-9 {
      padding-top: 16px;
    }
  }
}

.character-list__category-list {
  overflow: auto;
  height: calc(100% - 77px);
  position: relative;

  h2 {
    @include sub-header-font;
    font-size: 17px;
  }
}

.character-list__manual-sort {
  display: flex;
  width: 100%;
  justify-content: center;
  color: $yellow-3;
  cursor: pointer;

  &:hover {
    color: $yellow-1;
  }
}

.character-list__category-title {
  word-break: break-all;
}

.character-list__list {
  .list-group-item__wrapper {
    &.dragging {
      transition: 0.01s;
      visibility: hidden;
    }
    &:not(.dragging) {
      visibility: visible;
    }
  }
  .dropzone-indicator {
    height: 0px;
    transition: height 0.25s linear;
    background-color: transparent;
    &.display {
      height: 50px;
    }
  }

  .list-group-item {
    padding-right: 4px;
    cursor: grab;

    .character-list__item-inner {
      display: flex;
      align-items: center;
      position: relative;

      .character-list__item-buttons {
        min-width: 71px;
        position: absolute;
        right: 0;
        visibility: hidden;

        &:hover {
          visibility: visible;
        }

        button {
          padding: 4px 8px;
        }
      }

      .character-list__item-inner__image-wrapper {
        img {
          max-width: 75px;
          max-height: 75px;
        }

        margin-right: 10px;
      }

      .list-group-item-heading.withImage {
        font-size: 16px;
      }
    }

    &:hover {
      background: $new-item-background;

      .character-list__item-buttons {
        visibility: visible;
      }
    }

    &.selected {
      border: 2px dashed $blue-6;
      background-color: $gray-7;
      margin-bottom: 0;
    }
  }
}

.character-list__list.darkmode {
  .list-group-item__wrapper {
    &.dragging {
      transition: 0.01s;
      visibility: hidden;
      height: 0px;
    }
    &:not(.dragging) {
      visibility: visible;
      height: 100%;
    }
  }

  .list-group-item {
    cursor: grab;
    background-color: $darkmode-darker-background;
    color: $darkmode-text;
    border-color: $darkmode-darker-border;

    &:hover {
      background: $darkmode-background;
    }

    &.selected {
      border: 2px dashed $darkmode-border;
      background-color: $darkmode-darker-border;
      margin-bottom: 0;
    }

    &.isDroppable {
      box-sizing: border-box;
      border: 2px dashed $gray-6;
      padding: 9px 14px;
    }

    h6 {
      color: $darkmode-v2-text;
    }

    p {
      color: $darkmode-darker-text;
    }
  }
}

.character-list__new {
  text-align: center;
  cursor: pointer;
  color: $new-item-border-color;
}

.character-list__new:hover {
  background: $new-item-background;
}

.character-list__character-wrapper {
  height: 100%;
  position: relative;
  overflow: auto;
}

.character-list__character-view {
  @include well;
  padding: 20px;
  max-height: 100%;
  overflow: auto;

  display: flex;
  align-items: flex-start;

  .character-list__character-view__left-side {
    width: 18%;
    position: sticky;
    top: 0;
    border-right: 1px solid #eee;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .character-list__character-view__right-side {
    width: 79%;
    height: 100%;
    padding: 4px;
    margin-left: 8px;
    overflow-y: auto;
  }
}

.character-list__character {
  cursor: pointer;

  .character-list__character-notes {
    border: 2px dashed transparent;
    display: flex;
    justify-content: space-between;

    .image-circle-large {
      margin-left: 100px;
      margin-bottom: 20px;
    }

    .character-list__right-side {
      .glyphicon {
        color: $blue-7;
        visibility: hidden;
        margin: 5px;
      }
    }
  }

  .slate-editor__wrapper {
    word-break: break-word;
    .slate-editor__word-counter {
      display: none !important;
    }
  }

  &:hover {
    .character-list__character-notes {
      border: 2px dashed $blue-7;
      border-radius: 4px;
      background-color: $gray-9;

      .glyphicon {
        visibility: visible;
      }
    }
  }

  .character-list__character__edit-form {
    padding-left: 5px;
    display: flex;

    .character-list__inputs__normal {
      width: 50%;
      padding-right: 3%;
      border-right: solid 1px #eee;
    }

    .character-list__inputs__custom {
      width: 50%;
      padding-right: 3%;
      padding-left: 3%;
    }
  }

  .character-list__character__tabs {
    .nav-tabs {
      user-select: none;

      li.draggable {
        a {
          user-select: auto;
          cursor: grab;
        }

        a:active {
          cursor: grabbing;
        }

        &.isDroppable a {
          box-sizing: border-box;
          border: 2px dashed $gray-6;
          padding: 9px 14px;
        }
      }
    }

    .tab-content {
      padding: 16px;
      border-left: 1px solid $gray-8;
      border-right: 1px solid $gray-8;

      & > .tab-pane {
        .card-dialog__custom-attributes__wrapper {
          margin-bottom: 16px;
        }

        .template-attr__description-label {
          @include application-text;
          font-size: 14px;
          color: $neutral-gray-4;
          margin-top: -4px;
        }
      }

      & > .tab-pane .template-picker__link {
        cursor: pointer;
        margin-left: 4px;
        vertical-align: middle;

        &.dark {
          color: $gray-2;
        }
      }

      & > .tab-pane .template-tab__details {
        display: flex;
        justify-content: space-between;

        .text-danger {
          color: $red-5 !important;
        }
      }
    }

    .slate-editor__wrapper {
      margin: 0;
    }
  }

  .btn-toolbar {
    display: flex;
    flex-direction: row-reverse;
  }
}

.character-list__character__edit-image-wrapper {
  display: flex;
  align-items: center;

  .character-list__character__edit-image {
    max-width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.character-list__character-view.darkmode {
  background-color: $darkmode-darker-background;
  color: $darkmode-text;
  border-color: $darkmode-darker-border;

  .character-list__character:hover {
    .character-list__character-notes {
      border: 2px dashed $darkmode-border;
      background-color: $darkmode-darker-border;
    }
  }

  .character-list__character__tabs {
    .nav-tabs {
      border-bottom-color: $darkmode-border;
    }

    .nav-tabs > li:not(.active) > a:not(:hover) {
      color: $darkmode-link-text;
      border-bottom-color: $darkmode-border;
    }

    .tab-content {
      border-color: $darkmode-border;

      .card-dialog__custom-attributes-configuration-link {
        color: $darkmode-link-text;
      }

      > .active {
        position: relative !important;
      }

      .template-picker__link.dark {
        color: $darkmode-link-text;
      }
    }
  }

  .card-dialog__button-bar {
    border-color: $darkmode-border;
  }

  h4 {
    color: $darkmode-darker-text;
  }
}

.character-list__character.editing {
  cursor: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.darkmode {
    background-color: $darkmode-darker-background;
    color: $darkmode-text;
  }

  .lock-icon__wrapper {
    position: absolute;
    top: 67%;
    font-size: 12px;
    padding: 20px;

    svg {
      font-size: 1.5rem;
    }
  }

  .character-list__character__tabs {
    .slate-editor__wrapper {
      position: relative;
      .slate-editor__word-counter {
        display: block !important;
      }
    }
  }
}

.darkmode {
  .character-list {
    h1 {
      background-color: transparent;
    }
  }
  .character-list__character-view.darkmode {
    background-color: #24252c;
    color: #aaa;
    border-color: $darkmode-v2-border;

    .character-list__character:hover {
      .character-list__character-notes {
        border: 2px dashed $darkmode-v2-border;
        background-color: $darkmode-v2-background;
      }
    }

    .character-list__character__tabs {
      .tab-content,
      .nav-tabs {
        border-color: $darkmode-v2-border;

        > li:not(.active) > a:not(:hover) {
          color: #bbb;
          border-bottom-color: $darkmode-v2-border;
        }

        li.active {
          a {
            background-color: $darkmode-v2-background;

            &:hover {
              background-color: $darkmode-v2-background;
            }
          }
        }
      }

      .tab-content {
        padding: 16px;
        border-left: 1px solid $darkmode-v2-border;
        border-right: 1px solid $darkmode-v2-border;

        .card-dialog__custom-attributes-configuration-link {
          color: #bbb;
        }
      }
    }

    .card-dialog__button-bar {
      border-color: $darkmode-v2-border;
    }

    .character-list__character-view__left-side {
      border-right: 1px solid $darkmode-v2-border;
    }

    .card-dialog__button-bar {
      border-color: $darkmode-v2-border;
    }
  }

  .character-list__character.darkmode {
    &.editing {
      background-color: #24252c;
      color: #ddd;
    }

    .character-list__character__edit-form {
      .character-list__inputs__normal {
        border-right: solid 1px $darkmode-v2-border;
      }
    }
  }

  .character-list__list.darkmode {
    .list-group-item {
      background-color: $darkmode-v2-background;
      color: $darkmode-v2-darker-text;
      border-color: $darkmode-v2-border;

      h6 {
        color: $darkmode-v2-text;
      }

      &.selected {
        border: 1px dashed #aaa;
        background-color: #24252c;
      }
    }
  }

  .character-list__character__edit-image-wrapper {
    .btn-warning {
      border-color: $darkmode-v2-border;
      color: $darkmode-v2-text;

      &:hover {
        background-color: #bfa93f !important;
        border: #8c7228 solid 1px;
      }
    }
  }

  .character-list__character__edit-image-wrapper {
    .btn-warning {
      border-color: $darkmode-v2-border;
      color: $darkmode-v2-text;

      &:hover {
        background-color: #bfa93f !important;
        border: #8c7228 solid 1px;
      }
    }
  }
}
