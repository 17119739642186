@import 'vars';

.import-modal__wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  > * + * {
    margin: 18px 10px;
  }

  .import-dialog__body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    overflow-y: auto;
    height: 100%;
    margin: 0;
    @include application-text;

    .dashboard__recent-files {
      width: 100%;
      .container-fluid {
        width: 100%;
        padding: 0;
      }

      .sticky-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .sticky-table-table {
          position: absolute;
        }
      }
    }

    .import-dialog__option-lists {
      @include application-text;
      font-size: 18px;
      display: flex;
      flex-wrap: wrap;
      padding: 0 16px;
      overflow: auto;
      height: 100%;
      width: 100%;

      .list-wrapper {
        margin: 0 12px 8px 12px;
        border: 2px solid $gray-9;
        border-radius: 15px;
        max-width: 350px;
        min-width: 200px;

        input[type='checkbox'] {
          margin-right: 10px;
        }

        label {
          display: flex;
          align-items: center;
        }

        .import-dialog__item-image-tick {
          margin-left: 16px;
          font-size: 12px;
        }
      }

      .list-title {
        font-size: 25px;
        font-weight: bold;
        background-color: $gray-9;
        font-variant: small-caps;
        text-transform: lowercase;
        text-align: center;
        padding-top: 6px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 8px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        cursor: pointer;

        .checkbox {
          margin: 0;
          label {
            font-weight: bold;
          }
          input[type='checkbox'] {
            top: 9.5px;
          }
        }

        > span {
          user-select: none;
        }
      }

      ul {
        list-style-type: none;
        padding: 0 20px;
        font-size: 17px;
        color: $neutral-gray-3;

        &.import-modal__category-body {
          li {
            .import-dialog__item-title {
              .checkbox {
                label {
                  user-select: none;
                }
              }
            }
          }
        }
      }

      .checkbox input[type='checkbox'],
      .radio input[type='radio'] {
        top: 2px;
      }
    }
  }

  .import-modal__beats-section-wrapper,
  .import-modal__category-section-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    min-width: 200px;
    margin-left: 18px;
  }

  .import-modal__category-section__heading,
  .import-modal__category-section__sub-heading {
    display: flex;
    align-items: center;
    margin: 5px 0;

    svg {
      margin-right: 10px;
      cursor: pointer;
    }

    h3,
    h5,
    h6 {
      margin: 0;
    }

    input[type='checkbox'] {
      margin-top: 0;
      margin-right: 5px;
    }
  }

  .import-dialog__header {
    h3 {
      margin-top: 0;
    }
  }

  hr {
    margin-top: 0px;
    width: 100%;
  }

  .import-dialog__footer {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 0;

    .file-location__button-with-icon {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    .btn-toolbar {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0;

      div {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
      div:last-child {
        justify-content: flex-end;
      }

      button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      div {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
      div:last-child {
        justify-content: flex-end;
      }

      button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      .import-btn {
        background-color: $brand-orange;
        color: #fff;

        &:hover {
          border-color: $brand-orange;
          color: inherit;
          background-color: transparent;
        }
      }
    }
  }
}

body.darkmode {
  .import-dialog__body {
    .import-dialog__option-lists {
      .list-wrapper {
        border-color: $darkmode-border;
      }
      .list-title {
        background-color: $darkmode-darker-background;
        color: $darkmode-text;
      }
    }

    .import-dialog__option-lists {
      ul {
        color: $darkmode-darker-text !important;
      }
    }

    .container-fluid {
      @include application-text;
      .sticky-table {
        border: 1px solid $darkmode-v2-border;

        .sticky-table-row {
          color: $darkmode-v2-darker-text;
        }
      }
    }
  }

  .btn-toolbar {
    .import-btn {
      background-color: $brand-orange;
      color: #fff;

      &:hover {
        color: #bbb;
      }
    }
  }
}
