@import '../colors';
@import '../vars';

.dashboard__help {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-bottom: 8px;

  h1 {
    font-size: 20pt;
    margin-top: 0;
    margin-bottom: 20px;
  }

  hr {
    border-top: 1px solid $neutral-gray-7;
  }

  iframe,
  webview {
    height: calc(100% - 49px);
    width: 100%;
    border: 1px solid $neutral-gray-7;
  }
}

.dashboard__help__item {
  .btn {
    font-size: 16px;
  }

  &.links {
    display: flex;
    flex-wrap: wrap;
  }
  &.actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    & > .btn {
      margin-right: 16px;
    }

    & > div {
      margin-left: auto;
      display: flex;
      align-items: center;
      .btn {
        margin-left: 8px;
      }
      .form-group {
        margin-bottom: 0;
      }
      .form-control {
        width: 300px;
      }
    }
  }
}

.dashboard__help__search-docs {
  display: flex;
  justify-content: center;
  & > div {
    width: 620px;
    text-align: center;
    padding: 2em;
    position: relative;

    p {
      color: $neutral-gray-5;
    }
  }
  input {
    height: 55px;
    border: white;
    border-radius: 5px;
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    padding: 4px 14px;
  }
  button,
  button:active,
  button:focus {
    color: #fff;
    background: #ff5000;
    border-radius: 0 5px 5px 0;
    border: 1px solid #ff4500;
    transition: color 0.2s;
    text-shadow: none;
    font-size: 18px;
    padding: 0 1.5em;
    height: 55px;
    position: absolute;
    top: 32px;
    right: 32px;

    &:hover {
      background: #ff4500;
      color: white;
    }
  }
}
