@import 'vars';

.note-list {
  h1 {
    text-align: center;
    @include application-text;
  }

  .tab-body {
    width: 99vw;
    overflow: hidden;

    .row {
      height: 100%;
    }

    .col-sm-3,
    .col-sm-9 {
      height: 100%;
      position: relative;
    }

    .col-sm-9 {
      padding-top: 16px;
    }
  }
}

.note-list__category-list {
  overflow: auto;
  height: calc(100% - 77px);
  position: relative;

  h2 {
    @include sub-header-font;
    font-size: 17px;
  }
}

.note-list__manual-sort {
  display: flex;
  width: 100%;
  justify-content: center;
  color: $yellow-3;
  cursor: pointer;

  &:hover {
    color: $yellow-1;
  }
}

.note-list__category-title {
  word-break: break-all;
}

.note-list__list {
  .dropzone-indicator {
    background-color: transparent;
    transition: height 0.25s linear;
    height: 0px;
    &.display {
      height: 50px;
    }
  }

  .list-group-item__wrapper {
    &.dragging {
      transition: 0.01s;
      visibility: hidden;
      height: 0px;
    }
    &:not(.dragging) {
      visibility: visible;
      height: 100%;
    }
  }

  .list-group-item {
    padding-right: 4px;
    cursor: grab;

    .note-list__item-inner {
      display: flex;
      align-items: center;
      position: relative;

      .note-list__item-buttons {
        min-width: 71px;
        position: absolute;
        right: 0;
        visibility: hidden;

        &:hover {
          visibility: visible;
        }

        button {
          padding: 4px 8px;
        }
      }

      .note-list__item-inner__image-wrapper {
        img {
          max-width: 75px;
          max-height: 75px;
        }

        margin-right: 10px;
      }

      .list-group-item-heading.withImage {
        font-size: 16px;
      }
    }

    &:hover {
      background: $new-item-background;

      .note-list__item-buttons {
        visibility: visible;
      }
    }

    &.selected {
      border: 2px dashed $blue-6;
      background-color: $gray-7;
      margin-bottom: 0;
    }
  }
}

.note-list__list.darkmode {
  .list-group-item {
    cursor: grab;
    background-color: $darkmode-darker-background;
    color: $darkmode-text;
    border-color: $darkmode-darker-border;

    &:hover {
      background: $darkmode-background;
    }

    &.selected {
      border: 2px dashed $darkmode-border;
      background-color: $darkmode-darker-border;
      margin-bottom: 0;
    }

    h6 {
      color: $darkmode-text;
    }

    p {
      color: $darkmode-darker-text;
    }
  }
}

.note-list__new {
  text-align: center;
  cursor: pointer;
  color: $new-item-border-color;
}

.note-list__new:hover {
  background: $new-item-background;
}

.note-list__note-wrapper {
  height: 100%;
  position: relative;
  overflow: auto;
}

.note-list__note-view {
  @include well;
  padding: 20px;
  max-height: 100%;
  overflow: auto;

  display: flex;
  align-items: flex-start;

  .note-list__note-view__left-side {
    overflow: hidden;
    width: 18%;
    position: sticky;
    top: 0;
    border-right: 1px solid #eee;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .note-list__note-view__right-side {
    width: 79%;
    height: 100%;
    padding: 4px;
    margin-left: 8px;
    overflow-y: auto;
  }
}

.note-list__note {
  cursor: pointer;

  .note-list__note-notes {
    border: 2px dashed transparent;
    display: flex;
    justify-content: space-between;

    .image-circle-large {
      margin-left: 100px;
      margin-bottom: 20px;
    }

    .note-list__right-side {
      .glyphicon {
        color: $blue-7;
        visibility: hidden;
        margin: 5px;
      }
    }
  }

  &:hover {
    .note-list__note-notes {
      border: 2px dashed $blue-7;
      border-radius: 4px;
      background-color: $gray-9;

      .glyphicon {
        visibility: visible;
      }
    }
  }

  .note-list__note__edit-form {
    padding-left: 5px;
    display: flex;

    .note-list__inputs__normal {
      width: 50%;
      padding-right: 3%;
      border-right: solid 1px #eee;
    }

    .note-list__inputs__custom {
      width: 50%;
      padding-right: 3%;
      padding-left: 3%;
    }
  }

  .btn-toolbar {
    display: flex;
    flex-direction: row-reverse;
  }

  &:not(.editing) {
    .slate-editor__wrapper {
      word-break: break-word;
      .slate-editor__word-counter {
        display: none;
      }
    }
  }
}

.note-list__note__edit-image-wrapper {
  display: flex;
  align-items: center;

  .note-list__note__edit-image {
    max-width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.note-list__note-view.darkmode {
  background-color: $darkmode-darker-background;
  border-color: $darkmode-darker-border;

  .note-list__note:hover {
    .note-list__note-notes {
      border: 2px dashed $darkmode-border;
      background-color: $darkmode-darker-border;
    }
  }

  h4 {
    color: $darkmode-darker-text;
  }
}

.note-list__note.editing {
  cursor: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.darkmode {
    background-color: $darkmode-darker-background;
    color: #aaa;
  }

  .lock-icon__wrapper {
    top: 64%;
    font-size: 12px;
    padding: 20px;

    svg {
      font-size: 1.5rem;
    }
  }

  .note-list__rce__wrapper {
    position: relative;

    .lock-icon__wrapper {
      position: absolute;
      padding: 20px;
      top: 50%;
      font-size: 1.5rem !important;

      svg {
        font-size: 2rem;
        margin: auto;
        display: flex;
      }
    }

    .slate-editor__wrapper {
      position: relative;

      .slate-editor__word-counter {
        display: flex;
      }
    }
  }
}

.darkmode {
  .note-list__note-wrapper {
    .editing {
      background-color: #24252c;
    }
  }

  .note-list__note-view.darkmode {
    background-color: #24252c;
    color: #aaa;
    border-color: $darkmode-v2-border;

    &.editing {
      background-color: #24252c;
      color: #ddd;
    }

    .note-list__note {
      &:hover {
        .note-list__note-notes {
          border: 2px dashed $darkmode-v2-border;
          background-color: $darkmode-v2-background;
        }
      }

      .note-list__note__edit-form {
        .note-list__inputs__normal {
          border-right: solid 1px $darkmode-v2-border;
        }
      }
    }

    .note-list__note-view__left-side {
      border-right: 1px solid $darkmode-v2-border;
    }
  }

  .note-list__list.darkmode {
    .list-group-item {
      background-color: $darkmode-v2-background;
      color: $darkmode-v2-darker-text;
      border-color: $darkmode-v2-border;

      &.selected {
        border: 1px dashed #aaa;
        background-color: #24252c;
      }

      h6 {
        color: $darkmode-v2-text;
      }
    }
  }
}
