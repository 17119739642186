@import '../colors';
@import 'navigation';
@import 'verify';
@import 'expired';
@import 'update_notifier';
@import 'files';
@import 'templates';
@import 'backups';
@import 'account';
@import 'options';
@import 'help';
@import 'darkmode';
@import 'settingsWizard';
@import 'fonts';

$header-height: 40px;

#dashboard__react__root {
  @include application-text;
  height: 95vh;

  // .dashboard__header {
  //   -webkit-app-region: drag;
  //   -webkit-user-select: none;
  //   width: 100vw;
  //   height: $header-height;
  //   background-color: $neutral-gray-9;
  //   background-image: linear-gradient(to top, $neutral-gray-7, $neutral-gray-8, $neutral-gray-9);
  //   display: flex;
  //   align-items: center;
  //   // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  //   .nav-spacer {
  //     width: 100px;
  //     &.win32 {
  //       display: flex;
  //       justify-content: center;
  //     }
  //   }

  //   .nav-right {
  //     display: flex;
  //     flex: 1;
  //     justify-content: center;
  //     align-items: center;

  //     .spacer {
  //       padding: 0 4px;
  //     }
  //   }

  //   .nav-3right-buttons {
  //     -webkit-app-region: no-drag;
  //     margin-right: 11px;
  //     svg {
  //       font-size: 22px;
  //       margin: 0 5px;
  //       vertical-align: bottom;
  //       cursor: pointer;

  //       &:hover {
  //         background-color: $neutral-gray-7;
  //       }
  //     }
  //   }
  // }

  // .beamer-wrapper {
  //   width: 40px;
  //   cursor: pointer;
  //   #beamer-bell {
  //     cursor: pointer;
  //     .beamer_icon {
  //       top: -6px;
  //       right: -16px;
  //     }
  //     svg {
  //       cursor: pointer;
  //       color: $gray-0;
  //       vertical-align: bottom;
  //     }
  //   }
  // }

  .dashboard__main,
  main {
    height: 100vh; // can't use variable here: $header-height
    width: 100%;
    background-color: $gray-9;
    overflow: auto;
    display: grid;
    grid-template-areas: 'nav main';
    grid-template-columns: auto 1fr;
  }

  .dashboard__body {
    grid-area: main;
    overflow: hidden;

    &:has(.dashboard__files) {
      display: flex;
      height: 100%;
      flex-direction: column;
    }

    .beamer-wrapper.win32 {
      position: absolute;
      right: 0;
      top: 55px;
    }
  }

  .dashboard__close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

#dashboard__react__root.darkmode {
  background-color: $darkmode-v2-background;
}

.darkmode {
  .dashboard__body.darkmode {
    background-color: $darkmode-v2-card-background;
  }
  .dashboard__main.darkmode {
    background-color: $darkmode-v2-card-background;
    color: #ddd;

    nav {
      background-color: $darkmode-v2-darker-background;
    }

    .dashboard__navigation-tab {
      background-color: $darkmode-v2-darker-background;
      border-bottom-color: #3c3c3c;
      color: #ddd;

      &:hover {
        background-color: $darkmode-v2-background;
      }

      &:first-child {
        border: none;
      }
    }

    .dashboard__navigation-tab.selected {
      background-color: $darkmode-v2-card-background;
    }

    hr {
      border-color: #4b4d4f;
    }

    .dashboard__new-files__item {
      color: #ccc;
      background: #19171d;

      &:hover,
      &:active {
        box-shadow: none;
        border-left: 4px solid #f47e38;
      }
    }

    .dashboard__new-files__item.icon svg {
      color: #f47e38;
    }

    button#new-files-dropdown {
      background: none;
    }

    .secondary-text,
    .sub-header {
      color: $darkmode-v2-darker-text;
    }

    .dashboard__body {
      .dashboard__account {
        .dashboard__account__body {
          .dashboard__user-info {
            background-color: $darkmode-v2-background;
            > *:not(h2) {
              background-color: $darkmode-v2-background;
            }
          }
        }
      }
    }
  }
}
