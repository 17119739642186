@import 'vars';

.series__container {
  width: 100vw;
  overflow: auto;
}

.edit-book__container {
  padding: 16px 20px 10px 20px;

  h2 {
    @include sub-header-font;
    padding-left: 20px;
    margin-top: 0;
  }

  .btn-toolbar {
    display: flex;
    justify-content: flex-end;
  }
}

.book-list__container {
  padding: 10px 40px;
  position: relative;

  h2 {
    @include sub-header-font;
    margin-top: 0;

    .glyphicon {
      visibility: hidden;
      cursor: pointer;
      color: $blue-5;
    }

    &:hover {
      .glyphicon {
        visibility: visible;
      }
    }
  }
}

.book-list__list {
  display: flex;
  padding: 40px 5px;
  padding-top: 0;
  overflow: hidden;
}

.book-list__droppable {
  user-select: none;
  outline: none;
}

.book-dialog {
  .modal-content {
    border-radius: 20px;
  }
  hr {
    margin: 0px;
  }
  .book-dialog__footer {
    padding: 15px;
  }
  .book-dialog__body {
    padding: 15px;
  }
}

.book-dialog.darkmode {
  .modal-body,
  .modal-footer,
  .modal-header {
    .close {
      color: $darkmode-text;
    }

    color: $darkmode-text;
    background-color: $darkmode-background;
  }
}

// css book copy/pasted from the 😱 internet
// https://freefrontend.com/css-book-effects/ -> https://codepen.io/HighFlyer/pen/LaXrgV

.book-container {
  z-index: 1;
  perspective: 3000px;
  margin-left: 35px;
  margin-right: 35px;
  text-align: center;
  user-select: none;

  .hover-options {
    visibility: hidden;
    width: max-content;

    &.hovering {
      visibility: visible;
    }
  }

  &.add {
    padding-top: 36px;
  }

  &.gu-transit {
    // being dragged
    border: 2px dashed $gray-7;
  }

  &.darkmode {
    .book {
      background: linear-gradient(45deg, $gray-4 0%, $gray-5 100%);
      box-shadow: 13px 13px 8px 0px rgba(90, 90, 90, 0.6);
    }

    .left-side {
      background-color: $gray-4;

      &.add {
        background-color: $gray-4;
      }
    }

    .cover {
      background: linear-gradient(45deg, $gray-4 0%, $gray-5 100%);

      &.add {
        background: linear-gradient(45deg, $gray-6 0%, $gray-7 100%);

        .book-container__add {
          div:hover {
            background-color: rgba(108, 172, 228, 0.3);
            color: $darkmode-text;
          }
        }
      }
    }
  }
}

.book-container .book {
  position: relative;
  display: block;
  // width: 400px;
  // height: 550px;
  width: 175px;
  height: 242.7px;
  margin: 5% auto;
  border-radius: 2px 4px 4px 2px;
  // background: linear-gradient(45deg,  #DAD5DC 0%, #f2ebf4 100%);
  background: linear-gradient(45deg, $gray-7 0%, $gray-8 100%);
  // some ideas for backgrounds
  // background: linear-gradient($blue-8, $blue-9);
  // background: linear-gradient($blue-8, $blue-9), url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e6f6ff' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  // background-color: $blue-8;
  // background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23e6f6ff' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");

  // font-family: acumin-pro, sans-serif;
  box-shadow: 13px 13px 8px 0px rgba(151, 146, 153, 0.6);
  // font-weight: 400;
  color: $gray-0;
  transform-style: preserve-3d;
  transition: transform 0.5s;

  &.add {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}

.book-container .book:hover,
.book-container .book.hovering {
  transform: rotate3d(0, 1, 0, 35deg);

  &.add {
    transform: none;
  }
}

.book-container .book > div {
  display: block;
  position: absolute;
}

.book-container .front {
  transform-style: preserve-3d;
  transform-origin: 0% 50%;
  transition: transform 0.5s;
  transform: translate3d(0, 0, 20px);
  z-index: 10;
}

.book-container .front > div {
  // width: 400px;
  // height: 550px;
  width: 175px;
  height: 242.7px;
  border-radius: 0 3px 3px 0;
  box-shadow: inset 4px 0 10px rgba(0, 0, 0, 0.1);

  padding: 10px;
  padding-left: 15px;

  &.cover.add {
    padding: 0;
    padding-left: 8px;
  }

  h6 {
    @include body-text-font;
    text-align: center;
    font-size: 16pt !important;
    line-height: 1.4;

    .glyphicon {
      color: $blue-5;
    }
  }

  .book-container__add {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: $blue-5; // #6cace4

    // color: rgb(108,172,228); // #6cace4
    div {
      font-size: 20px;
      width: 100%;
      padding: 10px 5px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        // border: 3px dashed $blue-7;
        background-color: rgba(108, 172, 228, 0.1); // #6cace4;
        // transform: scale(1.03);
      }
    }

    div.use-template {
      @include application-text;
      text-align: center;
      line-height: 1.3;
    }

    // .use-template {
    //   width: 100%;
    //   height: 75px;
    //   border: 3px dashed transparent;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   cursor: pointer;
    //   transition: transform 0.6s $cubic;
    //   h5 {
    //     @include application-text;
    //     font-size: 20px;
    //     line-height: 1.3;
    //   }
    //   &:hover {
    //     border: 3px dashed $blue-7;
    //     background-color: $blue-9;
    //     transform: scale(1.03);
    //   }
    // }
  }

  &.smaller-font {
    h6 {
      font-size: 16px !important;
    }
  }

  &.very-small-font {
    h6 {
      font-size: 12px !important;
    }
  }
}

.book-container .left-side {
  // width: 40px;
  width: 35px;
  // left: -20px;
  left: -18px;
  // height: 550px;
  height: 242.5px;
  // background-color: rgb(232,229,234);
  background-color: $gray-7;
  transform: rotate3d(0, 1, 0, -90deg);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &.add {
    background: none;
    background-color: $gray-9;
  }
}

.book-container .front:after {
  content: '';
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: -1px;
  width: 1px;
}

.book-container .cover:after {
  content: '';
  position: absolute;
  top: 0;
  left: 6px;
  bottom: 0;
  width: 2px;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 1px 0 3px rgba(255, 255, 255, 0.1);
}

.book-container h2 {
  // width: 500px;
  width: 242px;
  // height: 40px;
  height: 35px;
  color: $gray-0;
  // font-size: 15px;
  line-height: 35px;
  padding-right: 20px;
  text-align: right;
  transform-origin: 0 0;
  transform: rotate(90deg) translateY(-45px);
  @include sub-header-font;
}

.cover {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  overflow: hidden;
  // background: linear-gradient(45deg,  #DAD5DC 0%, #f2ebf4 100%);
  background: linear-gradient(45deg, $gray-7 0%, $gray-8 100%);

  // background-color: #bcccdc;
  // background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23dae2ec' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  &:not(.add) {
    word-break: break-word;
  }

  &.add {
    background: none;
    background-color: $gray-9;
  }
}

.left-side h2 span:first-child {
  font-weight: 400;
  font-size: 10px;
}

.book-container__cover-image-wrapper {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  max-height: 100%;

  img {
    max-height: 100%;
  }
}

.darkmode {
  .book-container.darkmode {
    background-color: transparent;
    .cover,
    .left-side,
    .book {
      background: $darkmode-v2-darker-background;
    }

    .book {
      box-shadow: none;
    }

    .book-container.darkmode .cover.add,
    .book-container.darkmode .left-side.add {
      background: #24252c;
    }

    .cover.add .book-container__add div:hover {
      background-color: $darkmode-v2-darker-background;
      color: #ddd;
    }

    .hover-options {
      .btn-group {
        .btn,
        .btn-default,
        .btn-warning,
        .btn-danger {
          background-color: $darkmode-v2-background;
          border-color: #4d4d4f;

          &:hover {
            background-color: $darkmode-v2-card-background;
          }
        }

        .btn-warning {
          .glyphicon {
            color: #bbb;
          }

          &:hover {
            background-color: #bfa93f !important;
            .glyphicon {
              color: $darkmode-v2-text;
            }
          }
        }

        .btn-danger {
          .glyphicon {
            color: #bbb;
          }

          &:hover {
            background-color: #bd3755 !important;
            .glyphicon {
              color: $darkmode-v2-text;
            }
          }
        }
      }
    }
  }

  .book-container .front > div .book-container__add {
    color: #bbb;
  }

  .file-actions-wrapper button.btn.btn-sm.btn-default:hover,
  .dashboard__navbar-form.navbar-form.navbar-right .btn:hover,
  .subnav__container.navbar.navbar-default.darkmode
    button:not(.active):not(.navbar-toggle):not(.chevron-btn):hover {
    color: #ccc;
    background-color: $darkmode-v2-nav-background;
  }

  .book-dialog.darkmode {
    .modal-body,
    .modal-footer,
    .modal-header {
      color: #ddd;
      background-color: $darkmode-v2-darker-background;
    }

    &.modal-dialog .btn-default {
      background: rgb(42, 43, 50);
    }

    &.modal-dialog .btn-default:hover {
      background: $darkmode-v2-darker-background;
    }

    .modal-footer {
      .btn-success:hover {
        background-color: $darkmode-v2-btn-primary;
      }
    }
  }

  .book-container__cover-image-wrapper {
    > svg {
      color: $darkmode-v2-darker-text;
    }
  }

  .book-list__container,
  .edit-book__container {
    h2 {
      @include sub-header-font;
      color: $darkmode-v2-heading-text;
    }
  }
}
