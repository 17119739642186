@import '../colors';
@import '../vars';

$yellow: #ffc72c;

.dashboard__account {
  height: 100%;
  overflow: auto;
  padding: 32px;

  hr {
    border-top: 1px solid $neutral-gray-7;
  }

  div.text-right p.secondary-text {
    margin-top: 8px;
  }

  
  .dashboard__acount__top {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    h1 {
      font-size: 20pt;
      margin-top: 0;
    }
    .start_plottr_pro__button {
      margin-left: 20px;
    }
  }
}

.dashboard__about__body {
  height: calc(100% - 42px); // 42px from height of .dashboard__account__nav-tabs
  overflow: auto;
  padding: 32px;
}

.dashboard__account__header {
  h1 {
    @include header-font;
    margin-top: 0;
    font-family: 'Yellowtail' !important;
    font-weight: 400 !important;
    font-size: 36pt !important;
    img {
      vertical-align: -80%;
      display: inline;
    }
  }
}

.dashboard__account__nav-tabs {
  display: flex;
  justify-content: center;
}

.dashboard__user-info {
  margin-bottom: 20px;
  border-width: 2px;
  border-radius: 8px;
  border-style: solid;
  background-color: white;
  align-items: center;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  @include body-text-font;

  > h2 {
    > svg {
      cursor: help;
    }
  }

  &.pro {
    border: 2px solid $red-5;
    color: #fff;

    > h2 {
      background-color: $red-5;
      color: #fff;
    }
  }

  &.community {
    border: 2px solid $blue-7;
    color: $darkmode-v2-background;

    > h2 {
      background-color: $blue-7;
      color: $darkmode-v2-background;
    }
  }

  &.plottr {
    border: 2px solid $orange;
    color: #fff;

    > h2 {
      background-color: $orange;
      color: #fff;
    }
  }

  &.trial {
    border: 2px solid $yellow;
    color: $darkmode-v2-background;

    > h2 {
      background-color: $yellow;
      color: $darkmode-v2-background;
    }
  }

  hr {
    width: 100%;
  }

  h2 {
    @include sub-header-font;
    color: #fff;
    font-size: 16pt;
    text-align: left;
    margin: 0;
    padding: 12px;
    display: flex;
    justify-content: center;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &.plottr,
  &.trial,
  &.pro,
  &.community {
    > h2 {
      font-weight: 700;
    }
  }

  .dashboard__user-info__wrapper {
    display: flex;
  }

  .dashboard__user-info__section-wrapper {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 100px;
    width: 100%;
    padding-bottom: 22px;
    color: $gray-0;
    padding-top: 24px;
    @include application-text;

    > * {
      width: 100%;
    }

    .expired__chooser {
      display: flex;
      justify-content: space-evenly;
      align-items: stretch;
      margin: 32px 0;
    }

    .expired__choice {
      width: 100%;
      padding: 10px;
      border: 3px solid $orange-5 !important;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.4s $cubic;
      color: $gray-1;
    }
    .expired-view {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-align: center;
        color: $gray-1;
        display: flex;
        justify-content: center;
      }
    }

    .expired__choice:hover {
      transform: scale(1.1);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
    }

    .flex {
      display: flex;

      &.flex-col {
        flex-direction: column;
      }
    }

    &.no-border {
      border-bottom: none !important;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:first-child {
      h6 {
        margin-top: 24px;
      }
    }

    h6 {
      display: flex;
      align-self: flex-start;
      font-weight: 700;
    }

    dd,
    dt {
      padding: 8px 0;
      word-break: break-word;

      &.lead {
        margin-bottom: 0;
        margin-top: -6px;
      }
    }

    .dashboard__user-info__details {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      align-items: center;

      &.logout-btn {
        align-items: flex-end;
        margin-bottom: -22px;
      }

      .dashboard__user-info__item {
        display: flex;
        width: 100%;
        > * {
          display: flex;
          width: 100%;
          align-items: center;
        }

        &.editing {
          align-items: flex-start;
        }

        button {
          width: auto;
          svg {
            width: 24px;
            height: 24px;
            fill: #fff;
          }
        }

        svg {
          width: 40px;
          height: 32px;
          fill: $blue-7;
        }

        .info-title {
          font-weight: 700;
          width: 250px;
        }
        .billing {
          @include application-text;
          color: $blue-7;
          font-size: 14pt;
          font-weight: 700;
          display: flex;
          letter-spacing: -0.2px;
        }

        .info-title :has(.billing) {
          width: 200px;
        }

        .dashboard__account__form-error {
          font-size: 14px;
          color: $red-5;

          a {
            text-decoration: underline;
            color: $red-5;
            font-weight: 700;
          }
        }

        .dashboard__acount-password-reset {
          gap: 5px;
          color: $blue-5;
          display: flex;
          align-items: center;

          input {
            width: 250px;

            &.error {
              border-color: $red-5;
            }
          }

          .password-reset-label {
            cursor: pointer;
          }

          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 16px;
            color: $orange;

            svg {
              color: $orange;
              fill: $orange;
              height: 20px;
            }
          }

          .dot-wrapper {
            display: flex;
            gap: 0px !important;
            cursor: auto;
          }

          > div:first-child {
            display: flex;
            align-items: center;
            color: $orange;
          }

          div:first-child {
            gap: 5px;
          }

          .editing {
            cursor: auto;
            flex-direction: column;
            align-items: flex-start !important;
          }

          .btn-success {
            display: flex;
            align-items: center;
          }
        }
      }

      .dot {
        height: 10px;
        width: 10px;
        margin-right: 1px;
        border-radius: 50%;
        display: inline-block;
        background: $neutral-gray-1;
      }
    }

    &.no-subscription {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 100px;
      padding-top: 40px;
      text-align: center;
      flex-direction: column;

      > * {
        display: flex;
        width: 100%;
        justify-content: center;
      }

      > span:first-child {
        margin-bottom: 40px;

        a {
          display: contents;
        }
      }

      h1 {
        font-family: 'Arial black', serif !important;
        font-size: 48px;
      }

      .dashboard__ad {
        display: flex;
        flex-direction: column;

        .dashboard__hero {
          gap: 48px;
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          margin-top: 32px;

          .btn-container {
            display: flex;
            flex-direction: row;
            gap: 12px;
            margin-top: 16px;

            button:first-child {
              width: 100%;
            }
            button {
              height: 72px;
              font-weight: 700;
              width: 70%;
              font-size: 1.25em;
            }
          }
        }
        img {
          display: flex;
          width: 50%;
          height: 100%;
        }

        ul {
          text-align: left;
          padding: 0;
        }

        li {
          gap: 16px;
          display: flex;
          align-items: center;
          list-style: none;
          font-size: 18px;

          svg {
            color: $green-5;
          }
        }

        .dashboard_ad-left {
          gap: 16px;
          width: 50%;
        }

        .review-container {
          display: flex;
          text-align: center;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 18px;

          > * {
            align-items: center;
            width: 100%;
            justify-content: center;
          }

          h4 {
            font-size: 20px;
            padding: 0 32px;
          }

          h4, h6 {
            font-style: italic;
          }
        }

        @media (max-width: 1300px) {
          .review-container {
            padding: 0 12px;

             h4 {
              font-size: 14px;
            }
          }
        }

        @media (max-width: 1024px) {
          .review-container {
            padding: 12px;

             h4 {
              padding: 0 18px;
              font-size: 16px;
            }
          }
        }

        .carousel-with-shadow {
          border-radius: 4px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          margin: 24px 0;
        }

      }

      .warning-strip {
        padding: 16px;
        background-color: $yellow-8;
        color: $red-0;
        width: auto;
        display: block;
        align-self: center;
        border-radius: 4px;
        align-items: center;
        gap: 5px;
        margin-bottom: 12px;
      }

      @media (max-width: 1023px) {
        .dashboard__ad {
          img {
            display: none;
          }

          .dashboard_ad-left {
            width: 100%;
          }
        }
        h1 {
          font-size: 24px;
        }

        .btn-container {
          button {
            font-size: 16px;
          }
        }
      }
    }

    @media (max-width: 768px) {
      &.no-subscription {
        padding: 50px;
      }
    }
  }

  @media (max-width: 768px) {
    .dashboard__user-info__section-wrapper {
      padding-left: 50px;
    }
  }

  .text-danger.darkmode {
    color: $red-6;
  }

  a.darkmode {
    color: $blue-6;
  }

  &.license-info__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    border: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 100%;

    h2 {
      color: white !important;
    }
  }

  .section-title__with-icon {
    display: flex;
    gap: 12px;
    align-items: center;

    svg {
      cursor: pointer;
    }
  }
}

.dashboard__about {
  padding: 32px 0;

  h1 {
    @include header-font;
    font-size: 20pt;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .dashboard__about__wrapper {
    display: flex;

    dd,
    dt {
      padding: 8px 0;
      font-size: large;
    }
  }
}

.darkmode {
  .dashboard__body {
    .account__main {
      .dashboard__user-info__section-wrapper {
        color: $darkmode-v2-darker-text;
  
        .dashboard__account__form-error {
          color: $orange-5 !important;
  
          a {
            text-decoration: underline;
            font-weight: 700;
            color: $orange-5 !important;
          }
        }
  
        .dashboard__user-info__item {
          .dot-wrapper {
            .dot {
              background-color: $darkmode-v2-text;
            }
          }
        }
      }
    }

    .dashboard__user-info {
      &.trial,
      &.community {
        > h2 {
          color: $darkmode-v2-background
        }
      }
    }
  }
}
