@import 'vars';

.acts-modal__wrapper {
  height: 100%;
  display: grid;
  grid-template-areas: 'header' 'body' 'footer';
  grid-template-rows: auto 1fr auto;
}

.acts-modal__header {
  grid-area: header;
  div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
  hr {
    margin-top: 8px;
  }
}

.acts-modal__body {
  grid-area: body;
  height: 100%;
}

.acts-modal__footer {
  grid-area: footer;
  & > div {
    display: flex;
    justify-content: space-between;
  }
  hr {
    margin-top: 0;
  }
}

.acts-modal__timeline-controls-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.acts-modal__timeline-view-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 32px;
}

.acts-modal__timeline-view-selector {
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
    color: $yellow-5;
  }
}

.acts-modal__select-line {
  margin-left: 5px !important;
  @include user-text;
  button {
    text-transform: capitalize;
  }
}

.acts-modal__timeline-view-dropdown {
}

.acts-modal__hierarchy-count-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 8px;
}

.acts-modal__hierarchy-count {
  width: 50px;
  text-align: center;
  color: $input-color;
}

.acts-modal__hierarchy-count-adjustment-control {
  background: none;
  border: none;
}

.acts-modal__levels-table {
  flex-grow: 1;
}

.acts-modal__levels-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dae2ec;
}

.acts-modal__levels-table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
}

.acts-modal__levels-table-cell {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 14%;
  font-family: 'Lato', sans-serif;
  box-shadow: none;
  margin: 0;
  outline: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  height: 40px;
  border-bottom: 2px solid #f2cb8000;
  transition: border-bottom 0.5s;

  .color-picker__choice {
    .btn {
      width: 26px;
      height: 26px;
      padding: 12px;
    }
  }

  .btn-warning {
    width: 28px;
  }

  .acts-modal__levels-spacer {
    width: 33px;
    &.small {
      width: 8px;
    }
  }

  &.align-left {
    justify-content: flex-start;
  }

  &.arrow {
    svg {
      margin-right: 4px;
    }
  }
}

.acts-modal__levels-table-cell--editing {
  margin-top: -2px;
  padding: 0;
  border-bottom: 2px solid $orange-6;
  color: $input-color;
}

input[type='checkbox'].acts-modal__toggle-control {
  margin: 0;
  height: 20px;
  width: 20px;
}

.darkmode {
  .acts-modal__timeline-controls-wrapper {
    .acts-modal__timeline-view-dropdown {
      .acts-modal__select-line {
        color: #bbb;
        #select-timeline-view {
          color: #bbb;
        }
        li {
          background-color: $darkmode-v2-background !important;
          a {
            background-color: $darkmode-v2-background !important;
            color: #bbb;
          }
        }
        li.disabled {
          &:hover {
            background-color: $darkmode-v2-background;
          }
          a {
            color: #666666;
          }

          .acts-modal__timeline-view-selector {
            color: #666666;
          }
        }
      }
    }

    .acts-modal__hierarchy-count-controls {
      color: #bfbfbf;
      .glyphicon {
        color: #bfbfbf;
      }
    }
  }

  .acts-modal__levels-table {
    .acts-modal__levels-table-header {
      color: #bfbfbf;
      border-bottom: 1px solid #888;

      .acts-modal__levels-table-cell {
        color: #bfbfbf;
      }
    }

    .acts-modal__levels-table-row {
      color: #bfbfbf;
    }
  }
}
