@import '../colors';
@import '../vars';

.dashboard__backups {
  height: 100%;
  padding: 32px;

  hr {
    border-color: $gray-8;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 4px;
    border-width: 5px;
  }

  .row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .dashboard__search {
    width: 300px;
  }
}

.dashboard__backups__header-div {
  display: flex;
  align-items: baseline;

  span.glyphicon {
    margin-left: 16px;
    font-size: larger;
  }

  h1 {
    font-size: 20pt;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.dashboard__backups__body {
  overflow: auto;
  height: 100%;
  padding-top: 16px;
  & > .container-fluid {
    margin: 0;
    padding: 0 2px;
  }

  .dashboard__backups__body-table-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.dashboard__backups__wrapper {
  height: calc(100% - 49px);
  overflow: auto;
  padding-bottom: 100px;
  padding-top: 4px;
}

.dashboard__backups__projects-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
  background-color: darken($gray-9, 3%);
  margin: 2px;
  cursor: pointer;
  user-select: none;

  & > div {
    display: flex;
    align-items: center;

    & > div {
      display: flex;
      margin-right: 12px;

      svg {
        color: $gray-5;
      }
    }
  }

  &.active {
    color: white;
    background-color: $gray-5;
    border-radius: 12px;

    svg {
      color: white;
    }

    .count-circle {
      border-color: white;
    }
  }

  .count-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid $gray-1;
    font-size: 10pt;
  }
}

.dashboard__backups__projects-table {
  padding: 0 16px 0 38px;
  margin-bottom: 4px;
  .dashboard__backups__columns {
    margin-top: 8px;
    color: $gray-5;
    user-select: none;
    width: 100%;
  }

  .dashboard__backups__header-columns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 12px;

    & > :not(:first-child) {
      width: 50%;
    }

    > :first-child {
      width: 100%;
    }
  }

  .dashboard__backups__project-row {
    &.row {
      margin: 4px 1px;
      border-radius: 8px;
      width: 100%;

      h6 {
        word-break: break-all;
        padding: 0px 12px;
      }

      > :first-child {
        width: 50%;
      }

      > :not(:first-child) {
        width: 50%;
        flex-direction: row;
        display: flex;
        align-items: center;
        padding: 0 5px;
      }
    }
    .dashboard__backups__project-backup {
      .dashboard__backups__item {
        padding: 10px 0;
        display: flex;
        align-items: center;

        & > div {
          width: 100%;
        }

        .dashboard__backups__item-actions {
          margin-left: 4px;
          display: flex;
          justify-content: flex-end;

          .dashboard__backups__item-button {
            margin-left: 8px;
            visibility: hidden;
          }
        }
      }

      .dashboard__backups__item-title {
        margin-bottom: 4px;
      }

      .dashboard__backups__item-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $gray-4;
      }
    }

    .dashboard__backups__item {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
    }

    .dashboard__backups__item-button {
      display: none;
    }

    small {
      color: $gray-5;
    }

    &:hover {
      background-color: darken($gray-9, 3%);

      .dashboard__backups__item-button {
        display: block;
        margin-left: 8px;
      }
    }
  }
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input {
  margin-top: 10px;
  width: 93px;
  height: 32px;
}

.custom-file-input::before {
  content: 'Select folder';
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  color: black;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.darkmode {
  .dashboard__backups__projects-summary {
    background-color: transparent;

    .count-circle {
      border-color: $gray-5;
      color: $gray-5;
    }

    &.active {
      background-color: $orange;
      .count-circle {
        border-color: $darkmode-v2-text;
        color: $darkmode-v2-text;
      }

      svg {
        color: $darkmode-v2-text;
      }
    }
  }
  .dashboard__backups__item,
  .dashboard__backups__project-row.row {
    background-color: transparent;

    .badge {
      background-color: #2c649e;
    }

    &:hover {
      color: #bbb;
      background-color: #19171d !important;
      box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 25%);
    }

    .icon svg {
      color: #bbb;
    }
  }

  .dashboard__backups__item-button {
    .btn-success {
      &.btn-xs {
        background-color: $darkmode-v2-btn-primary;
        &:hover {
          background-color: transparent;
          border-color: $darkmode-v2-btn-primary-border;
        }
      }
    }
  }
}
