/* glyphicons */
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('glyphicons-halflings-regular.woff2') format('woff2');
}

/* cyrillic-ext */
@font-face {
  font-family: 'Forum';
  font-style: normal;
  font-weight: 400;
  src: url('Forum-400-cyrillic-ext.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Forum';
  font-style: normal;
  font-weight: 400;
  src: url('Forum-400-cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Forum';
  font-style: normal;
  font-weight: 400;
  src: url('Forum-400-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Forum';
  font-style: normal;
  font-weight: 400;
  src: url('Forum-400-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: url('Plex-400-cyrillic-ext.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: url('Plex-400-cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: url('Plex-400-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 400;
  src: url('Plex-400-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: url('Plex-700-cyrillic-ext.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: url('Plex-700-cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: url('Plex-700-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-weight: 700;
  src: url('Plex-700-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('Lato-400-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('Lato-400-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('Lato-700-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('Lato-700-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Yellowtail';
  font-style: normal;
  font-weight: 400;
  src: url('Yellowtail.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin */
@font-face {
  font-family: 'OpenDyslexic';
  font-style: normal;
  font-weight: 400;
  src: url('OpenDyslexic-Regular.woff2') format('woff2');
  unicode-range: U+0020-007E, U+00A0-00FF, U+0100-017F, U+0180-024F, U+02C6, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'OpenDyslexic';
  font-style: normal;
  font-weight: 400;
  src: url('OpenDyslexic-Regular.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* American Typewriter */
@font-face {
  font-family: 'American Typewriter';
  font-style: normal;
  font-weight: 400;
  src: url('American Typewriter.woff2') format('woff2');
  unicode-range: U+0020-007E, U+00A0-00FF, U+0400-045F, U+0490-0491, U+04B0-04B1;
}

/* Andale Mono */
@font-face {
  font-family: 'Andale Mono';
  font-style: normal;
  font-weight: 400;
  src: url('Andale Mono.woff2') format('woff2');
  unicode-range: U+0020-007E, U+00A0-00FF, U+0400-045F, U+0490-0491, U+04B0-04B1;
}

/* Bradley Hand */
@font-face {
  font-family: 'Bradley Hand';
  font-style: normal;
  font-weight: 400;
  src: url('Bradleys Hand.woff2') format('woff2');
  unicode-range: U+0020-007E, U+00A0-00FF, U+0400-045F, U+0490-0491, U+04B0-04B1;
}

/* Brush Script MT */
@font-face {
  font-family: 'Brush Script MT';
  font-style: normal;
  font-weight: 400;
  src: url('Brush Script MT.woff2') format('woff2');
  unicode-range: U+0020-007E, U+00A0-00FF, U+0400-045F, U+0490-0491, U+04B0-04B1;
}

/* Didot */
@font-face {
  font-family: 'Didot';
  font-style: normal;
  font-weight: 400;
  src: url('Didot Regular.woff2') format('woff2');
  unicode-range: U+0020-007E, U+00A0-00FF, U+0400-045F, U+0490-0491, U+04B0-04B1;
}

/* Garamond */
@font-face {
  font-family: 'Garamond';
  font-style: normal;
  font-weight: 400;
  src: url('Garamond Regular.woff2') format('woff2');
  unicode-range: U+0020-007E, U+00A0-00FF, U+0400-045F, U+0490-0491, U+04B0-04B1;
}

/* Helvetica */
@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  src: url('Helvetica.woff2') format('woff2');
  unicode-range: U+0020-007E, U+00A0-00FF, U+0400-045F, U+0490-0491, U+04B0-04B1;
}

/* Luminari */
@font-face {
  font-family: 'Luminari';
  font-style: normal;
  font-weight: 400;
  src: url('Luminari.woff2') format('woff2');
  unicode-range: U+0020-007E, U+00A0-00FF, U+0400-045F, U+0490-0491, U+04B0-04B1;
}

/* Monaco */
@font-face {
  font-family: 'Monaco';
  font-style: normal;
  font-weight: 400;
  src: url('Monaco.woff2') format('woff2');
  unicode-range: U+0020-007E, U+00A0-00FF, U+0400-045F, U+0490-0491, U+04B0-04B1;
}
