@import 'vars';
@import 'colors';

.card-dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-dialog.darkmode {
  color: $darkmode-text;
  background-color: transparent;

  .card-dialog__description .card-dialog__tabs {
    .nav-tabs {
      border-bottom-color: $darkmode-border;
    }

    .nav-tabs > li:not(.active) > a:not(:hover) {
      color: #bbb;
    }

    .tab-content {
      border-color: $darkmode-border;

      .card-dialog__custom-attributes-configuration-link {
        color: $darkmode-link-text;
      }
    }

    .template-picker__link.dark {
      color: $darkmode-link-text;
    }
  }

  .card-dialog__left-side {
    border-color: $darkmode-border;
  }

  .card-dialog__button-bar {
    border-color: $darkmode-border;
  }
}

.card-dialog__title {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.card-dialog__body {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 90%;
  overflow: hidden;
}

.card-dialog__left-side {
  width: 20%;
  border-right: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
}

.card-dialog__description {
  width: 80%;
  padding-left: 10px;
  overflow-y: auto;
  font-size: 20px;

  .card-dialog__tabs {
    margin-bottom: 16px;
    font-size: 16px;
    height: calc(100% - 60px);

    .nav-tabs {
      user-select: none;

      li.draggable {
        a {
          user-select: auto;
          cursor: grab;
        }

        a:active {
          cursor: grabbing;
        }

        &.isDroppable a {
          box-sizing: border-box;
          border: 2px dashed $gray-6;
          padding: 9px 14px;
        }
      }
    }

    .tab-content {
      height: calc(100% - 44px);
      padding: 16px;
      border-left: 1px solid $gray-8;
      border-right: 1px solid $gray-8;
      border-bottom: 1px solid $gray-8;

      & > .tab-pane {
        height: 100%;
        overflow: auto;
        position: relative;

        .card-dialog__custom-attributes__wrapper {
          position: relative;
          margin-bottom: 16px;

          .lock-icon__wrapper {
            position: absolute;
            padding: 20px;
            top: 60% !important;
            font-size: 1.5rem;

            svg {
              font-size: 2rem;
              margin: auto;
              display: flex;
            }
          }
        }

        .template-attr__description-label {
          @include application-text;
          font-size: 14px;
          color: $neutral-gray-4;
          margin-top: -4px;
        }

        .lock-icon__wrapper {
          position: absolute;
          cursor: pointer;
          left: 42%;
          top: 40% !important;
          text-align: center;
          z-index: 100;
          width: max-content;
          padding: 40px;
          opacity: 80%;

          svg {
            font-size: 4rem;
            display: flex;
          }

          span,
          svg {
            display: flex;
            justify-content: center;
            align-self: center;
          }
        }
      }

      & > .tab-pane .template-picker__link {
        cursor: pointer;
        margin-left: 4px;
        vertical-align: middle;

        &.dark {
          color: $gray-2;
        }
      }

      & > .tab-pane .template-tab__details {
        display: flex;
        justify-content: space-between;

        .text-danger {
          color: $red-5 !important;
        }
      }
    }
  }

  textarea {
    @include rce-text-font;
  }

  .slate-editor__wrapper {
    height: 100%;
    overflow-y: auto;
    margin: 0;
    position: relative;
  }

  .slate-editor__editor {
    height: calc(100% - 30px);
    overflow-y: auto;
    @include rce-text-font;

    .slate-editor__word-counter {
      background-color: #fff;
      border: 1px solid $new-item-border-color;
      color: #102a42;
    }
  }
}

.card-dialog__custom-attributes__wrapper {
  position: relative;

  .lock-icon__wrapper {
    position: absolute;
    top: 55% !important;
  }

  .slate-editor__wrapper {
    overflow-y: unset;
    position: relative;
  }
}

.outline__description__editing {
  position: relative;
  width: 100%;
}

.rceLocked {
  pointer-events: none;
  display: flex;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, transparent 70%);
}

.hidden {
  display: none;
}

.card-dialog__custom-attributes {
  border: 2px solid $gray-9;
  padding: 15px 20px;
}

.card-dialog__custom-attributes-configuration-link {
  text-align: right;
  display: block;
  padding: 4px;
  margin-bottom: 4px;
  user-select: none;
}

.card-dialog__custom-attributes-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.card-dialog__custom-attributes-edit-controls {
  display: inline-block;
  margin-bottom: 5px;
  height: 100%;

  button {
    margin-left: 5px;
  }
}

.card-dialog__custom-attributes-editable-label {
  font-family: 'Lato', sans-serif;
  margin: 0;
  outline: 0;
  padding: 0;
  border: 0;
  height: 30px;
  margin-bottom: 3px;
  border-bottom: 2px solid #f2cb8000;
  transition: border-bottom 0.5s;
  font-weight: bold;

  &.darkmode {
    color: $darkmode-v2-text;
  }
}

.card-dialog__custom-attributes-editable-label--with-underline {
  border-bottom: 2px solid $orange-6;
  margin-bottom: 3px;
}

.card-dialog__dropdown-wrapper {
  display: flex;
  flex-direction: column;
}

.card-dialog__button-bar-create {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}

.card-dialog__select-line,
.card-dialog__select-card {
  margin-left: 5px !important;
  @include user-text;
}

.card-title-editor,
.card-description-editor {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}

.card-title-editor {
  margin-top: 10px;
  margin-bottom: 20px;
}

.card-title-editor__display,
.card-description-editor__display {
  margin: 0;
  flex-grow: 1;
}

.card-title-editor__display:hover,
.card-description-editor__display:hover {
  cursor: pointer;
}

.card-dialog__button-bar {
  padding-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  min-height: 50px;
}

.card-dialog__book-selector {
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    color: $yellow-5;
  }
}

.card-dialog__duplicate {
  margin-right: auto;
}

.card-dialog__delete {
  margin-right: auto;
}

.card-dialog__details-label {
  @include application-text;
}

.editor-toolbar {
  border-radius: 4px;
  border: 1px solid $gray-5;

  a {
    display: inline-block;
    text-align: center;
    text-decoration: none !important;
    color: $gray-2 !important;
    width: 30px;
    height: 30px;
    margin: 0;
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
    color: $gray-1 !important;
    border: 1px solid $gray-5;
  }

  .separator {
    font-style: normal;
    display: inline-block;
    width: 0;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #fff;
    color: transparent;
    text-indent: -10px;
    margin: 0 6px;
  }
}

.card-dialog__left-side {
  // The dropdowns for plotline and beat cause a horizontal scrollbar
  // when the titles are very long because the menu items don't wrap
  .dropdown-menu > li > a {
    white-space: normal;
  }

  .color-picker__box {
    .buttons {
      visibility: hidden;
    }

    &:hover {
      .buttons {
        visibility: visible;
      }
    }
  }
}

.card-dialog__left-side .dropdown .dropdown-menu {
  max-width: 100%;

  li a {
    word-break: break-all;
  }
}

.card-dialog__left-side .card-dialog__select-line.dropdown.btn-group:has(#select-book) {
  max-width: 100%;
  text-overflow: ellipsis;
}

.card-dialog__left-side .dropdown.btn-group {
  button {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.darkmode {
  .card-dialog__details-label {
    color: #bbb;
  }

  .card-dialog.darkmode {
    .card-dialog__description {
      .nav-tabs {
        color: #bbb;
        border-bottom-color: $darkmode-v2-border;

        li.active {
          a {
            background-color: $darkmode-v2-background;

            &:hover {
              background-color: $darkmode-v2-background;
            }
          }
        }
      }

      .slate-editor__editor {
        .slate-editor__word-counter {
          border: 1px solid $darkmode-v2-border;
          background-color: $darkmode-v2-background;
          color: $darkmode-v2-darker-text;
        }
      }

      .card-dialog__tabs {
        .tab-content,
        .card-dialog__button-bar {
          border-color: $darkmode-v2-border;

          &.btn-toolbar {
            .btn-success {
              background: transparent;
            }
          }
        }

        .tab-content {
          border-left: 1px solid $darkmode-v2-border;
          border-right: 1px solid $darkmode-v2-border;
          border-bottom: 1px solid $darkmode-v2-border;
        }
      }

      @include rce-text-font;
      @include rce-darkmode-font-color;

      h1 {
        @include rce-title-font;
        @include rce-title-darkmode-font-color;
      }

      h2 {
        @include sub-header-font;
        @include rce-subtitle-font;
        @include rce-subtitle-darkmode-font-color;
      }
    }

    .card-dialog__left-side {
      border-color: transparent;
    }
  }
}
