@import '../colors';
@import '../vars';

.settings-wizard__wrapper {
  height: 100vh;
  display: grid;
  grid-template-areas: 'header' 'body';
  grid-template-rows: auto 1fr;

  .onboarding__wrapper {
    display: grid;
    grid-template-areas: 'progress' 'step';
    grid-template-rows: auto 1fr;

    .onboarding__progress {
      grid-area: progress;
    }

    .onboarding__step {
      grid-area: step;
      max-height: 95%;
      width: 80vw;
      margin: 0 auto;
      margin-top: 16px;
      background-color: $gray-8;
      border-radius: 16px;
      display: grid;
      grid-template-areas: 'header' 'body' 'footer';
      grid-template-rows: auto 1fr auto;

      .step__header {
        grid-area: header;
        padding-bottom: 0;

        .onboarding__header {
          text-align: left;
          margin-left: 8%;
        }
      }

      .step__body {
        grid-area: body;
        padding-top: 0;
        padding-bottom: 0;
        overflow: scroll;
      }

      .step__body .onboarding__settings .dashboard__options__item .form-group {
        margin-left: 0;
      }

      .step__footer {
        grid-area: footer;
        margin-top: auto;
        padding-top: 0;
      }
    }
  }
}

.settings-wizard__header {
  grid-area: header;
  padding: 16px 0;
  text-align: center;
}

.settings-wizard__body {
  grid-area: body;
  overflow: auto;
}
